import { useState, useEffect } from 'react'

const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1)
    let days = []
    while (date.getMonth() === month) {
        days.push({ day: date.getDay(), date: date.getDate() })
        date.setDate(date.getDate() + 1)
    }
    return days
}

const getLastDaysPrevMonth = (month, year, nbDays) => {
    let date = new Date(year, month, 1)
    date.setDate(date.getDate() - 1)

    let days = []
    let i = 0
    while (i < nbDays) {
        days.push({ day: date.getDay(), date: date.getDate() })
        date.setDate(date.getDate() - 1)
        i++
    }

    return days.reverse()
}

const calcCalendarPage = (month, year) => {
    const nbDays = getDaysInMonth(month, year)
    const firstDay = nbDays[0]
    const lastDay = nbDays[nbDays.length - 1]
    let prev = getLastDaysPrevMonth(month, year, firstDay.day)
    if (prev.length === 7) prev = []

    let next = []
    const nextMonth = new Date(year, month, lastDay.date + 1)

    var times = 35 - (prev.length + nbDays.length)
    for (let i = 0; i < times; i++) {
        next.push({ day: nextMonth.getDay(), date: nextMonth.getDate() })
        nextMonth.setDate(nextMonth.getDate() + 1)
    }
    if (next.length === 7) next = []

    return {
        prev,
        current: nbDays,
        next,
    }
}

const applySelected = (
    selected,
    currentMonthDays,
    currentMonth,
    currentYear
) => {
    let copyCurrentMonthDays = { ...currentMonthDays }

    // const currentSelected = selected.filter(
    //     (date) =>
    //         date.getFullYear() === currentYear &&
    //         date.getMonth() === currentMonth
    // )

    if (!selected[0] && !selected[1]) {
        return copyCurrentMonthDays
    }

    const sDate = selected[0]
    const eDate = selected[1]

    copyCurrentMonthDays.current = copyCurrentMonthDays.current.map((day) => {
        const start = sDate.getDate() === day.date &&
            sDate.getMonth() === currentMonth &&
            sDate.getFullYear() === currentYear;

        const end = eDate.getDate() === day.date &&
            eDate.getMonth() === currentMonth &&
            eDate.getFullYear() === currentYear;

        return ({
            ...day,
            start,
            included: !(start || end) && !!eDate &&
                sDate < new Date(currentYear, currentMonth, day.date) &&
                new Date(currentYear, currentMonth, day.date) < eDate,
            end,
        })
    })

    return copyCurrentMonthDays
}

const useCalendar = ({ selected = [undefined, undefined] }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentMonthDays, setCurrentMonthDays] = useState(
        calcCalendarPage(new Date().getMonth(), new Date().getFullYear())
    )

    useEffect(() => {
        const newSelected = applySelected(selected, currentMonthDays, currentMonth, currentYear);
        setCurrentMonthDays(newSelected)
    }, [selected?.[0], selected?.[1]])

    const modifyPage = (month, year) => {
        setCurrentMonthDays(
            applySelected(selected, calcCalendarPage(month, year), month, year)
        )
        setCurrentMonth(month)
        setCurrentYear(year)
    }

    return {
        goPrevMonth: () => {
            if (currentMonth === 0) {
                modifyPage(11, currentYear - 1)
            } else {
                modifyPage(currentMonth - 1, currentYear)
            }
        },
        goNextMonth: () => {
            if (currentMonth === 11) {
                modifyPage(0, currentYear + 1)
            } else {
                modifyPage(currentMonth + 1, currentYear)
            }
        },
        goNextYear: () => modifyPage(currentMonth, currentYear + 1),
        goPrevYear: () => modifyPage(currentMonth, currentYear - 1),
        currentMonthDays,
        currentMonth,
        currentYear,
        goToCurrentMonth: () =>
            modifyPage(new Date().getMonth(), new Date().getFullYear()),
    }
}

export default useCalendar