import React from 'react';
import { withRouter } from 'react-router';
import { getNthItemFromPathname } from '../../../utils/index';

const CategoryHero = (props) => {
  const path = getNthItemFromPathname(props.location.pathname, 2);

  let categoryShorthand;
  let categoryText = '';

  switch (path) {
    case 'operations':
      categoryShorthand = 'ope';
      categoryText = 'OPS | HR';
      break;
    case 'sm':
      categoryShorthand = 'snm';
      categoryText = 'S&M';
      break;
    case 'fb':
      categoryShorthand = 'fnb';
      categoryText = 'F&B';
      break;
    default:
      props.history.replace('/notfound');
      break;
  }
  return (
    <section className="category-header">
      <div className="viewport">
        <div className="inner">
          <div className="top">
            <div className="txt-wrapper">
              <div className={`logo ${categoryShorthand}`}></div>
              <h1>{categoryText}</h1>
            </div>
            <div className="img-wrapper">
              <div className="gradient"></div>
              <div className="medias">
                <span
                  style={{
                    backgroundImage: `url('/assets/images/cats-${categoryShorthand}.jpg')`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(CategoryHero);
