import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { seriesFilter, parseCategoryID, sortByCreated } from "../../utils";
import get from "lodash.get";
import { useTranslation } from "react-i18next";
import { searchSeries, getSeriesTags } from "../../utils/data";

import Star from "./Star";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

export default function SeriesSlider(props) {
  const { t } = useTranslation();

  const { series, sorted, onSeriesClicked } = props;
  if (series && sorted) {
    series.sort(sortByCreated);
  }
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  return (
    <Swiper
      onSwiper={(swiper) => initSwiper(swiper)}
      slidesPerView={2}
      spaceBetween={10}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 16,
        },
      }}
    >
      {series &&
        series.map((s, i) => (
          <SwiperSlide key={s.id}>
            <Link
              to={`/series/${s.id}`}
              onSeriesClicked={() =>
                onSeriesClicked ? onSeriesClicked(s.id) : null
              }
              style={{ color: "#d2d2d2", textDecoration: "none" }}
            >
              <div className="slide-inner">
                <div className="medias">
                  {get(s, "seriePicture.uri", "") && (
                    <div
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_IMG_URL
                        }/storage/serie-picture/${get(
                          s,
                          "seriePicture.uri",
                          ""
                        )}`,
                      }}
                    />
                  )}
                </div>
                <div className="text">
                  <h2>{s.title}</h2>
                  {s.speakers.length > 0 &&
                    s.speakers.filter((s) => s !== null).length > 0 && (
                      <div className="author">
                        <span>{t("With")}: </span>
                        <span>
                          {s.speakers
                            .filter((spkr) => spkr !== null)
                            .map((spkr, i) => `${spkr.name} ${spkr.surname}`)
                            .join(", ")}
                        </span>
                      </div>
                    )}
                  <div className="info">
                    <span
                      className={`cat ${
                        parseCategoryID(s.category).categoryShorthand
                      }`}
                    >
                      {parseCategoryID(s.category).categoryName}

                      {s.isSustainability && (
                        <span className="sustainability-tag-wrapper">
                          <span className="icon-sustainability"></span>
                          <span className="text-sustainability">
                            sustainability
                          </span>
                        </span>
                      )}
                    </span>
                    <span className="videos">
                      {`${s.videos.filter(seriesFilter).length}${
                        s.videos.filter(seriesFilter).length > 1
                          ? t("XVideos")
                          : t("XVideo")
                      }`}
                    </span>
                  </div>
                </div>
                <div
                  className={`hover-content ${
                    parseCategoryID(s.category).categoryShorthand
                  }`}
                >
                  <div className="title">{s.title}</div>
                  <div className="description">{s.description}</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      <div
        id="prevbutton"
        className="swiper-button-prev swiper-button-disabled"
        onClick={() => swiper.slidePrev()}
      ></div>
      <div
        id="nextbutton"
        className="swiper-button-next"
        onClick={() => swiper.slideNext()}
      ></div>
    </Swiper>
  );
}

function SeriesSliderHome(props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(process.env.REACT_APP_ESSENTIALS);
  const [seriesList, setSeries] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const { series, sorted, onSeriesClicked } = props;
  if (series && sorted) {
    series.sort(sortByCreated);
  }
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  useEffect(() => {
    const laodTags = async () => {
      const tags = await getSeriesTags();
      setTags(
        tags?.data?.sort((a, b) =>
          a.id === process.env.REACT_APP_ESSENTIALS
            ? -1
            : b.id === process.env.REACT_APP_ESSENTIALS
            ? 1
            : 0
        )
      );
    };

    laodTags();
  }, []);

  useEffect(() => {
    const loadSeries = async () => {
      const res = await searchSeries({ tags: selected, range: "[0,15]" });
      setSeries(res?.data);
      setLoading(false);
    };

    setLoading(true);
    loadSeries();
  }, [selected]);

  return (
    <>
      <div className="tags">
        <div className="tags-wrapper">
          {tags?.map((tag, idx) => {
            return (
              <div
                key={idx}
                onClick={() => setSelected(tag.id)}
                className={`tag-item${selected === tag.id ? " active" : ""}${
                  tag.id === process.env.REACT_APP_ESSENTIALS
                    ? " essentials"
                    : ""
                }`}
              >
                {}

                {tag.id === process.env.REACT_APP_ESSENTIALS ? (
                  <Star color="#FFEA32" />
                ) : !!tag.tagPicture ? (
                  <img
                    className="icon"
                    src={`${process.env.REACT_APP_IMG_URL}/storage/tag-picture/${tag.tagPicture.uri}`}
                    alt=""
                  />
                ) : null}
                {/* <ELEM color={tag.id === process.env.REACT_APP_ESSENTIALS ? '#FFEA32' : '#D2D2D2'} /> */}
                <span className="tag-text">{tag.name}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="controler-line">
        <div className="text-wrap">
          <span className="bold">
            {tags?.find((e) => e.id === selected)?.subtitle || "Series"}
          </span>
          <a href="/search" className="see-all">
            See all
          </a>
        </div>

        <div className="button-wrap">
          <div
            className="button series-prev-btn"
            onClick={() => swiper?.slidePrev()}
          />
          <div
            className="button series-next-btn"
            onClick={() => swiper?.slideNext()}
          />
        </div>
      </div>

      {loading ? (
        <span>{t("Loading")}</span>
      ) : (
        <Swiper
          onSwiper={(swiper) => initSwiper(swiper)}
          slidesPerView={2}
          spaceBetween={10}
          navigation={{
            // nextEl: ".swiper-button-next",
            // prevEl: ".swiper-button-prev",
            nextEl: ".series-next-btn",
            prevEl: ".series-prev-btn",
          }}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 16,
            },
          }}
        >
          {seriesList?.map((s, i) => (
            <SwiperSlide key={s.id}>
              <Link
                to={`/series/${s.id}`}
                onSeriesClicked={() =>
                  onSeriesClicked ? onSeriesClicked(s.id) : null
                }
                style={{ color: "#d2d2d2", textDecoration: "none" }}
              >
                <div className="slide-inner" style={{ position: "relative" }}>
                  <div className="medias">
                    {get(s, "seriePicture.uri", "") && (
                      <div
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/serie-picture/${get(
                            s,
                            "seriePicture.uri",
                            ""
                          )}`,
                        }}
                      />
                    )}
                  </div>
                  <div className="text">
                    <h2>{s.title}</h2>
                    {s.speakers.length > 0 &&
                      s.speakers.filter((s) => s !== null).length > 0 && (
                        <div className="author">
                          <span>{t("With")}: </span>
                          <span>
                            {s.speakers
                              .filter((spkr) => spkr !== null)
                              .map((spkr, i) => `${spkr.name} ${spkr.surname}`)
                              .join(", ")}
                          </span>
                        </div>
                      )}
                    <div className="info">
                      <span
                        className={`cat ${
                          parseCategoryID(s.category).categoryShorthand
                        }`}
                      >
                        {parseCategoryID(s.category).categoryName}

                        {s.isSustainability && (
                          <span className="sustainability-tag-wrapper">
                            <span className="icon-sustainability"></span>
                            <span className="text-sustainability">
                              sustainability
                            </span>
                          </span>
                        )}
                      </span>
                      <span className="videos">
                        {`${s.videos.filter(seriesFilter).length}${
                          s.videos.filter(seriesFilter).length > 1
                            ? t("XVideos")
                            : t("XVideo")
                        }`}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`hover-content ${
                      parseCategoryID(s.category).categoryShorthand
                    }`}
                  >
                    <div className="title">{s.title}</div>
                    <div className="description">{s.description}</div>
                  </div>

                  {s.tags?.find(
                    (e) => e.id === process.env.REACT_APP_ESSENTIALS
                  ) && (
                    <div className="star-bg">
                      <div
                        className={`star-layer${
                          s.allComplete ? " selected" : ""
                        }`}
                      >
                        <img
                          className="full"
                          src="/assets/icons-svg/star_full.svg"
                          alt=""
                        />
                        <img
                          className="notfull"
                          src="/assets/icons-svg/star_empty.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
}

SeriesSlider.Home = SeriesSliderHome;

SeriesSlider.List = ({ series, t }) => {
  return (
    <div className="series-list">
      {series.map((s, i) => (
        <Link
          key={i}
          to={`/series/${s.id}`}
          style={{ color: "#d2d2d2", textDecoration: "none" }}
        >
          <div className="slide-inner">
            <div className="medias">
              {get(s, "seriePicture.uri", "") && (
                <div
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMG_URL
                    }/storage/serie-picture/${get(s, "seriePicture.uri", "")}`,
                  }}
                />
              )}
            </div>
            <div className="text">
              <h2>{s.title}</h2>
              {s.speakers.length > 0 &&
                s.speakers.filter((s) => s !== null).length > 0 && (
                  <div className="author">
                    <span>{t("With")}: </span>
                    <span>
                      {s.speakers
                        .filter((spkr) => spkr !== null)
                        .map((spkr, i) => `${spkr.name} ${spkr.surname}`)
                        .join(", ")}
                    </span>
                  </div>
                )}
              <div className="info">
                <span
                  className={`cat ${
                    parseCategoryID(s.category).categoryShorthand
                  }`}
                >
                  {parseCategoryID(s.category).categoryName}

                  {s.isSustainability && (
                    <span className="sustainability-tag-wrapper">
                      <span className="icon-sustainability"></span>
                      <span className="text-sustainability">
                        sustainability
                      </span>
                    </span>
                  )}
                </span>
                <span className="videos">
                  {` ${s.videos.filter(seriesFilter).length}${
                    s.videos.filter(seriesFilter).length > 1
                      ? t("XVideos")
                      : t("XVideo")
                  }`}
                </span>
              </div>
            </div>
            <div
              className={`hover-content ${
                parseCategoryID(s.category).categoryShorthand
              }`}
            >
              <div className="title">{s.title}</div>
              <div className="description">{s.description}</div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
