import React, { Fragment, useEffect, useState } from 'react';
import Wrapper from "../wrapper";
import "./styles.scss";
import SearchBar from "./searchBar";
import moment from "moment/moment";
import { useGlobalContext } from "../../../store/global";
import { getRecipes } from '../../../utils/data'
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 30;

const concatRecipe = (previousRecipes, newRecipes) => {
    const joinedRecipes = { ...previousRecipes }

    for (const recipe of newRecipes) {
        if (!!joinedRecipes[recipe.category.id]) {
            joinedRecipes[recipe.category.id].recipes.push(recipe)
        } else {
            joinedRecipes[recipe.category.id] = {
                ...recipe.category,
                recipes: [recipe]
            }
        }
    }

    return joinedRecipes
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const Directory = () => {
    const { t } = useTranslation();
    const [q, setQ] = useState("");
    const debouncedQ = useDebounce(q, 500);
    const [page, setPage] = useState(0);
    const { openRecipeModal } = useGlobalContext();

    const [order, setOrder] = useState("latest");
    const [isLoading, setIsLoading] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [recipes, setRecipes] = useState();

    const getNextPage = async () => {
        setIsLoading(true);
        const nexPage = page + 1;
        const res = await getRecipes({
        q: debouncedQ,
        range: `[${nexPage * PAGE_SIZE},${
            nexPage * PAGE_SIZE + (PAGE_SIZE - 1)
        }]`,
        // sort: `["createdAt", "${order === 'earliest' ? 'ASC' : 'DESC'}"]`
        });
        setRecipes((e) => concatRecipe(e, res?.data));
        setPage((e) => (res.data.length < PAGE_SIZE ? null : nexPage));
        setIsLoading(false);
    };

    useEffect(() => {
        const launchSearch = async () => {
            const res = await getRecipes({
                q: debouncedQ,
                range: `[0,${PAGE_SIZE - 1}]`,
                // sort: `["createdAt", "${order === 'earliest' ? 'ASC' : 'DESC'}"]`
            });
            setRecipes(concatRecipe({}, res?.data));
            if (res.data.length < PAGE_SIZE) {
                setPage(null)
            }
            setIsLoading(false);
        };

        setIsLoading(true);
        setRecipes(undefined);
        launchSearch();
        setPage(0);
    }, [debouncedQ, order]);

    const onOrderClick = (order) => {
        setOrder((e) => (e === order ? undefined : order));
        setShowOrder(false);
    };

    const isEmpty = !isLoading && !recipes?.length;

    return (
        <Wrapper>
            <div id="content">
                <section className="classics">
                    <div className="viewport">
                        <div className="inner">
                            <SearchBar
                                searchValue={q}
                                onSearchChange={setQ}
                                onClick={() => {}}
                            />

                            <div className='order-wrapper'>
                                {!isLoading &&
                                    <div className="order">
                                        <span className="label">
                                            <span className="icon-sorting" />
                                            Sort by
                                        </span>

                                        <span
                                            className="value"
                                            onClick={() => setShowOrder((e) => !e)}
                                        >
                                            {!order
                                                ? "Default"
                                                : {
                                                    latest: "Latest",
                                                    earliest: "Earliest",
                                                    'a-z': 'A-Z',
                                                }[order]}

                                            <div className={`overlay${showOrder ? " open" : ""}`} />

                                            <div className={`popup${showOrder ? " open" : ""}`}>
                                                <div
                                                    className={order === "latest" ? "active" : ""}
                                                    onClick={() => onOrderClick("latest")}
                                                >
                                                    <span className="icon-check" />
                                                    <span>Latest</span>
                                                </div>
                                                <div
                                                    className={order === "earliest" ? "active" : ""}
                                                    onClick={() => onOrderClick("earliest")}
                                                >
                                                    <span className="icon-check" />
                                                    <span>Earliest</span>
                                                </div>
                                                <div
                                                    className={order === "a-z" ? "active" : ""}
                                                    onClick={() => onOrderClick("a-z")}
                                                >
                                                    <span className="icon-check" />
                                                    <span>A-Z</span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                }
                            </div>

                            <div>
                                {Object.keys(recipes || {}).map((cat, index) => {
                                    const category = recipes[cat]

                                    return (
                                        <Fragment key={category.id}>
                                            <div className='category'>
                                                <span className='name'>
                                                    {category.name}
                                                </span>
                                                <span className='count'>
                                                    {`${category.recipes.length} ${category.recipes.length > 1 ? 'Recipes' : 'Recipe'}`}
                                                </span>
                                            </div>
                                            <div>
                                                {category.recipes.map((recipe, jndex) => (
                                                    <div
                                                        key={recipe.id}
                                                        className={`recipe${
                                                            recipe.userComplete ? " watched" : ""
                                                        }`}
                                                        onClick={() => openRecipeModal({
                                                            apId: recipe.apId,
                                                            wistiaHashId: recipe.wistiaHashId,
                                                            description: recipe.ingredients,
                                                        })}
                                                    >
                                                        <div className="wrapper-info">
                                                        <div className="name">
                                                            <span className="icon-check" />
                                                            <span>{recipe.title}</span>
                                                        </div>
                                                        <div className="time">
                                                            <span>{parseTime(recipe.duration)}</span>
                                                            <span>{`Realeased on ${moment(recipe.createdAt).format("MMM DD, YYYY")}`}</span>
                                                        </div>
                                                        </div>
                                                        <span className="mobile-released">{`Realeased on ${moment().format("MMM DD, YYYY")}`}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Fragment>
                                        // <div key={index} className="category">
                                        //     <h2>{category.title}</h2>
                                        //     <div className="recipes">
                                        //         {category.recipes.map((recipe, index) => (
                                        //             <div key={index} className="recipe">
                                        //                 <div className="thumbnail" />
                                        //                 <div className="info">
                                        //                     <h3>{recipe.title}</h3>
                                        //                     <p>{recipe.duration} min</p>
                                        //                 </div>
                                        //             </div>
                                        //         ))}
                                        //     </div>
                                        // </div>
                                    )
                                })}
                            </div>

                            {page !== null && !isEmpty && (
                                <div className="btn-wrapper">
                                <button
                                    disabled={isLoading}
                                    className="tertiary small"
                                    onClick={getNextPage}
                                >
                                    <span>{isLoading ? t("Loading") : t("Discover_More")}</span>
                                </button>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    )
}

export default Directory;