import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SeriesSlider from '../../ui/seriesSlider';
import SustainabilityHero from './sustainabilityHero';
import SearchBar from '../search/searchBar';
import SearchResults from '../search/searchResults';
import queryString from 'query-string';
import useSearch from '../../../store/hooks/useSearch';
import { searchSeries, getSeriesById, getRecommendedQuizzes } from '../../../utils/data';
import { getNthItemFromPathname, parseCategory, parseCategoryID, seriesFilter } from '../../../utils';
import Banner1 from '../../layout/banner1';
import { useGlobalContext } from '../../../store/global';
import get from "lodash.get";
import EpisodesSlider from "../../ui/episodesSlider";
import QuizItem from "../../ui/QuizItem";
import { useTranslation } from 'react-i18next'

const Sustainability = (props) => {
  const { setLoading } = useGlobalContext();
  const [category, setCategory] = useState(null);
  const [color, setColor] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [searchValue, setSearchValue] = useState(queryString.parse(window.location.search).q || '');

  const [searchQ, setSearchQ] = useState(queryString.parse(window.location.search).q || '');
  const [searchTags, setSearchTags] = useState(queryString.parse(window.location.search).tags || '');
  const [searchFilter, setSearchFilter] = useState(queryString.parse(window.location.search).filter || '');

  const [latestSeries, setLatestSeries] = useState([]);
  const [recommendedQuizzes, setRecommendedQuizzes] = useState([]);

  const [searchSort, setSearchSort] = useState('');
  const [sortKey, setSortKey] = useState('latest');

  const { t } = useTranslation();

  const {
    episodes,
    series,
    quizzes,
  } = useSearch(
    searchQ,
    searchTags,
    searchFilter,
    category, // always null
    true, // startLoading = true
    searchFilter === 'speaker',
    searchSort,
    true, // isSustainability = true
  );

  const handleFilter = (q, tags, filter, sort = '') => {
    setSearchQ(q);
    setSearchTags(tags);
    setSearchFilter(filter);
    setSearchSort(sort);

    if (sort === '["releaseDate","DESC"]' || sort === '["createdAt","DESC"]' || sort === '') {
      setSortKey('latest');
    } else if (sort === '["releaseDate","ASC"]' || sort === '["createdAt","ASC"]') {
      setSortKey('earliest');
    } else if (sort === '["morningViewCount","DESC"]') {
      setSortKey('mostViewed');
    }
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else {
      setSearchQ('')
      setSearchValue('')
      setSearchTags('')

      if (queryString.parse(window.location.search).filter) {
        setSearchFilter(queryString.parse(window.location.search).filter)
      } else {
        setSearchFilter('')
      }
    }

    const getPageData = async () => {
      setLoading(true);
      
      const catColor = parseCategory('sustainability').categoryColor;
      const range = '[0, 2]';
      let tempLatestSeries = await searchSeries({
        isSustainability: true,
        range,
        sort: { createdAt: -1 }
      })


      let promises = [];
      tempLatestSeries.data.map(serie => {
        promises.push(getSeriesById(serie.id))
      })
      let [serieDetail0, serieDetail1, serieDetail2] = await Promise.all(promises);
      tempLatestSeries.data = [];
      if (serieDetail0?.data) {
        tempLatestSeries.data.push(serieDetail0.data);
      }
      if (serieDetail1?.data) {
        tempLatestSeries.data.push(serieDetail1.data);
      }
      if (serieDetail2?.data) {
        tempLatestSeries.data.push(serieDetail2.data);
      }

      const rq = await getRecommendedQuizzes({isSustainability: true});
      rq.data.map(q => {
        q.category = {
          id: q.category
        }
      })

      setLatestSeries(tempLatestSeries);
      setColor(catColor);
      setLoading(false);
      setRecommendedQuizzes(rq.data);
    };

    getPageData();
  }, [props.location.pathname]);

  const handleContinueToWatch = (serie) => {
    let link = `/series/${serie?.id}`;
    if (serie.videos.length === 0) {
      return link;
    }
    let serieCompleted = true;
    for (const v of serie.videos) {
      if (v.video?.id && v.video?.userComplete === false) {
        link = `/video?id=${v.video?.id}`;
        serieCompleted = false;
        break;
      }
    }
    if (serieCompleted && serie.videos[0]?.video?.id) {
      link = `/video?id=${serie.videos[0]?.video.id}`;
    }
    return link;
  };

  return (
    <div id="content">
      <div className="back-button" onClick={() => props.history.goBack()}>
        <span className="icon-arrowup2"></span> {t('Back')}
      </div>
      <SustainabilityHero />

      <SearchBar
        searchValue={searchValue}
        onSearchChange={(e) => setSearchValue(e)}

        searchQ={searchQ}
        onQChange={(e) => handleFilter(e, searchTags, searchFilter)}
        searchTags={searchTags}
        onTagChange={(e) => handleFilter(searchQ, e, searchFilter)}
        searchFilter={searchFilter}
        onFilterChange={(e) => handleFilter(searchValue, '', e)}

        sortKey={sortKey}
        onSortChange={(e) => handleFilter('', searchTags, searchFilter, e.value)}

        isCategory={true}
        color={color}
        filterCategory={category}

        isSustainability={true}
      />

      {(quizzes.isLoading && !quizzes.list.length)
      || (episodes.isLoading && !episodes.list.length)
      || (series.isLoading && !series.list.length) ? null : (
        <>
          {(searchFilter === 'quiz') && (quizzes.list.length !== 0) ? (
            <>
              {searchQ === '' && searchTags === '' && searchSort === '' && recommendedQuizzes.length > 0 && (
                <section className="series-videos in-search-page">
                  <div className="viewport">
                    <div className="inner">
                      <h2 className="title">{t('Recommended_For_You')}</h2>
                      <div className="video-list">
                        {recommendedQuizzes.map((v, i) => i < recommendedQuizzes.length ? <QuizItem key={v.id} quiz={v} isSustainability={true} /> : null)}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              <SearchResults.Quiz
                videos={quizzes.list}
                limit={quizzes.list.length}
                t={t}
                isSustainability={true}
              />
              {!quizzes.endReach ? (
                <div className="load-more">
                  <button className="tertiary small" onClick={quizzes.fetchMore}>
                    <span>
                      {quizzes.isLoading ? t('Loading') : t('Discover_More')}
                    </span>
                  </button>
                </div>
              ) : null}              
            </>
          ) : (
            null
          )}

          {(searchFilter === 'episodes' ||
            searchFilter === 'speaker') && (episodes.list.length !== 0) ? (
            <>
              <SearchResults
                videos={episodes.list}
                limit={episodes.list.length}
              />
              {!episodes.endReach ? (
                <div className="load-more">
                  <button className="tertiary small" onClick={episodes.fetchMore}>
                    <span>
                      {episodes.isLoading ? t('Loading') : t('Discover_More')}
                    </span>
                  </button>
                </div>
              ) : null}              
            </>
          ) : (
            null
          )}

          {(searchFilter === '' || searchFilter === 'series') && (series.list.length !== 0) ? (
            <section className="series in-search-page">
              <div className="viewport">
                {searchQ === '' && searchTags === '' && latestSeries?.data && latestSeries.data.length !== 0 && (
                  <div className="inner">
                    <h2 className="title">{t('Latest_Series')}</h2>

                    <div className="latest-list">
                    {latestSeries?.data?.map((s, i) => (
                      <div className="latest-serie" key={i}>
                        <div className="latest-serie-info">
                          <div className="media-wrapper">
                            {get(s, "seriePicture.uri", "") &&
                              <div
                                className="media"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.REACT_APP_IMG_URL
                                  }/storage/serie-picture/${get(
                                    s,
                                    "seriePicture.uri",
                                    ""
                                  )}`,
                                }}
                              />
                            }
                          </div>
                          <div className="text-wrapper">
                            <div className="category-wrapper">
                              <div
                                className={`cat ${
                                  parseCategoryID(s.category).categoryShorthand
                                }`}
                              >
                                {parseCategoryID(s.category).categoryName}
                              </div>
                              <div className="sustainability-tag-wrapper">
                                <span className="icon-sustainability"></span>
                                <span className="text-sustainability">sustainability</span>
                              </div>
                            </div>
                            <div className="title">{s.title}</div>
                            {s?.speakers?.length > 0 && (
                              <div className="author">
                                <span>{t('With')}: </span>
                                <span>{s.speakers.filter(spkr => spkr !== null).map((spkr, i) => `${spkr.name} ${spkr.surname}`).join(', ')}</span>
                              </div>
                            )}
                            <div className="description">{s.description}</div>
                            <div className="links">
                              <Link
                                className="link"
                                to={`/series/${s?.id}`}
                              >
                                {t('View_Details')}
                              </Link>
                              <Link
                                className="button"
                                to={handleContinueToWatch(s)}
                              >
                                <span className="icon-playbuttonbold"></span>
                                {t('Watch')}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="latest-serie-episodes">
                          <div className="info">
                            <h2 className="videos">
                              {` ${s.videos.filter(seriesFilter).length} ${s.videos.filter(seriesFilter).length > 1 ? t('XEpisodes') : t('XEpisode')}`}
                            </h2>
                          </div>
                          <EpisodesSlider serie={s} />
                        </div>
                        {s.quiz && s.quiz != null && (
                          <div className="latest-serie-quiz">
                            <div className="text-wrapper">
                              <img className="icon" src="/assets/images/quiztime.png"></img>
                              <div className="text">{t('Ready_For_A_Quiz')}</div>
                            </div>
                            <Link
                              className="button"
                              to={`/quiz?id=${s?.quiz?.id}`}
                            >
                              {t('Start')}
                            </Link>
                          </div>
                        )}
                      </div>
                    ))}
                    </div>
                  </div>
                )}
                <div className="inner">
                  <h2 className="title">{t('All_Series')}</h2>
                  <SeriesSlider.List series={series.list} t={t}/>
                  {!series.endReach ? (
                    <div className="load-more">
                      <button className="tertiary small" onClick={series.fetchMore}>
                        <span>
                          {series.isLoading ? t('Loading') : t('Discover_More')}
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          ) : (
            null
          )}

          {quizzes.list.length === 0 && !quizzes.isLoading
          && episodes.list.length === 0 && !episodes.isLoading
          && series.list.length === 0 && !series.isLoading ? (
            <div className="empty">
              <h2 className="title">
                {t('No_Result_For_Your_Search')}
              </h2>
            </div>
          ) : null}
        </>
      )}
      <Banner1 />
    </div>
  );
};

export default withRouter(Sustainability);
