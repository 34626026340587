import React from 'react';
import { withRouter } from 'react-router';
import { useTranslation, Trans } from 'react-i18next'

const SustainabilityHero = (props) => {
  const { t } = useTranslation();

  let categoryShorthand = 'sustainability';
  let categoryText = t('sustainability');
  let categorySolgan = t('sustainability_solgan');
  
  return (
    <section className="category-header">
      <div className="viewport">
        <div className="inner">
          <div className="top">
            <div className="txt-wrapper">
              <div className={`logo ${categoryShorthand}`}></div>
              <h1>{categoryText}</h1>
            </div>
            <div className="sustainability-desc-wrapper">
                <div className="solgan">{categorySolgan}</div>
                <div className="description">
                  <Trans i18nKey="sustainability_description">
                  </Trans>
                </div>
            </div>
            <div className="img-wrapper">
              <div className="gradient"></div>
              <div className="medias">
                <span
                  style={{
                    backgroundImage: `url('/assets/images/cats-${categoryShorthand}.jpg')`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(SustainabilityHero);
