import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SeriesHero from './seriesHero';
import SeriesVideos from './seriesVideos';
import SeriesSlider from '../../ui/seriesSlider';
import { getNthItemFromPathname, parseCategoryID, seriesFilter } from '../../../utils';
import { getSeriesById, getSeriesByCategory } from '../../../utils/data';
import Banner1 from '../../layout/banner1';
import get from 'lodash.get';
import { useGlobalContext } from '../../../store/global'

const Series = (props) => {

  const { setLoading } = useGlobalContext()

  // series category
  const [pageData, setPageData] = useState({
    seriesData: {},
    seriesVideos: [],
    seriesBySpeaker: [],
    seriesByCategory: [],
  });
  useEffect(() => {
    const seriesId = getNthItemFromPathname(props.location.pathname, 2);

    async function getPageData() {
      setLoading(true)
      const seriesData = await getSeriesById(seriesId);
      let speakerId
      let seriesBySpeaker
      if (get(seriesData, 'data.speakers[0].id', '') !== '') {
        speakerId = seriesData.data.speakers[0].id;
        seriesBySpeaker = await getSeriesByCategory('speakers', speakerId);
      }
      const seriesByCategory = await getSeriesByCategory('category', seriesData.data.category);
      const seriesVideos = [];
      setPageData((prevState) => ({
        ...prevState,
        seriesData: seriesData.data,
        seriesVideos: seriesData.data.videos,
        seriesQuiz: seriesData.data.quiz,
        seriesBySpeaker: seriesBySpeaker ? seriesBySpeaker.data : [],
        seriesByCategory: seriesByCategory.data,
      }));
      setLoading(false)
    }
    try {
      getPageData();
    } catch {
      props.history.replace('/notfound');
    }
  }, [props.location.pathname]);

  return (
    <>
      <div id="content">
        <div className="back-button" onClick={() => props.history.goBack()}>
          <span className="icon-arrowup2"></span> Back
        </div>
        <SeriesHero data={pageData.seriesData} />
        <SeriesVideos
          quiz={pageData.seriesQuiz}
          videos={pageData.seriesVideos.filter(seriesFilter)}
        />
        <section className="series">
          <div className="viewport">
            <div className="inner">
              <h2 className="title">Other series by this speaker</h2>
              <SeriesSlider series={pageData.seriesBySpeaker} sliderId={Math.random()} sorted />
            </div>
          </div>
        </section>
        <section className="series">
          <div className="viewport">
            <div className="inner">
              <h2 className="title">{`${
                parseCategoryID(pageData.seriesData.category).categoryName
              } Series`}</h2>
              <SeriesSlider series={pageData.seriesByCategory} sliderId={Math.random()} sorted />
            </div>
          </div>
        </section>
      </div>
      <Banner1 />
    </>
  );
};

export default withRouter(Series);
