import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { likeVideo } from '../../../utils/data';
import { useTranslation } from 'react-i18next'

export default function VideoInfo(props) {
  const {
    id,
    views,
    likes,
    userLike,
    title,
    tags,
    speakers,
    releaseDate,
    description,
    category,
    refreshVideo,
    shortLink1,
    shortLink2,
    anonViews,
    hideTop,
    userWatchlist,
    handleWatchlist,
    isSustainability,
  } = props;

  // we don't want the view count to increase when we refesh another part of the page
  const viewCountRef = useRef(views + anonViews);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!viewCountRef.current) {
      viewCountRef.current = views + anonViews;
    }
    return () => {
      viewCountRef.current = null
    }
  }, [views]);

  const sendLike = () => {
    likeVideo(id, !userLike).then((_) => {
      refreshVideo(id, null, true);
    });
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
    setShowTooltip(true)

    setTimeout(() => {
      setShowTooltip(false)
    }, 3000)
  }

  return (
    <>
      {!hideTop &&
        <div className="action-infos">
          <div className="right">
            <div className="views">
              <div onClick={handleShare} className="share-video">
                {t('Share_Video')}
                <span className={`${showTooltip ? 'visible' : ''}`}>
                  {t('Link_Copied')}
                </span>
              </div>
              <div onClick={handleWatchlist} className="share-video">
                <i className={userWatchlist ? 'icon icon-check' : 'icon icon-clock-outline-1'}></i>
                {userWatchlist ? t('In_Watch_List') : t('Watch_Later')}
              </div>
              <span className="view-count">{`${viewCountRef.current}${viewCountRef.current > 1 ? t('XViews') : t('XView')}`}</span>
              <span className={`like-count ${userLike ? 'active' : ''}`} onClick={sendLike}>
                <span className="icon-Heart"></span>
                {likes}
              </span>
            </div>
          </div>
        </div>
      }
      <div className="video-infos">
        <div className="left">
          <div className="video-title">{title}</div>
          <div className="video-category">
            <Link
              to={`/category/${category ? category.categorySlug : ''}`}
              style={{ color: `${category ? category.categoryColor : ''}`, textDecoration: 'none' }}
            >
              <span className={`cat ${category ? category.categoryShorthand : ''}`}>
                {category ? category.categoryName : ''}
              </span>
            </Link>
            <span className="tagline">{description}</span>
          </div>
          <div className="video-tags">
            {isSustainability && (
              <Link
                key={t.id}
                to={`/search?q=&tags=sustainability&filter=episodes`}
                style={{ color: 'white', textDecoration: 'none' }}
              >
                <span className="sustainability-tag-wrapper tag">
                  <span className="icon-sustainability"></span>
                  <span className="text-sustainability">sustainability</span>
                </span>
              </Link>
            )}
            {tags.map(
              (t) =>
                !t.disabled && (
                  <Link
                    key={t.id}
                    to={`/search?q=&tags=${t.id}&filter=episodes`}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    <span className="tag">{t.name}</span>
                  </Link>
                )
            )}
          </div>
          {speakers.length && speakers.filter(s => s !== null).length > 0 ? <div className="speaker">
            <span>{t('Speaker')}: </span>
            {speakers.filter(s => s !== null).map((s, i) => (
              <Link key={s.id} to={`/speaker/${s.id}`}>
                {s.name} {s.surname}
                {i + 1 >= speakers.length ? '' : ', '}
              </Link>
            ))}
          </div> : null}
          {releaseDate && 
          <div className="realease-date">
            {t('Released_On')}{`${moment(releaseDate).format('MMM DD, YYYY')}`}
          </div>}
          {shortLink1 && (
            <a
              className="realease-date discover-style"
              href={shortLink1}
              target="_blank"
              style={{ display: 'block' }}
            >
              {t('Related Link')}
            </a>
          )}
          {shortLink2 && (
            <a
              className="realease-date discover-style"
              href={shortLink2}
              target="_blank"
              style={{ display: 'block' }}
            >
             {t('Related Link')}
            </a>
          )}
        </div>
      </div>
    </>
  );
}
