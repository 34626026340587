import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../store/global';
import queryString from 'query-string';

const SignIn = (props) => {
  const { userAuthed, login, openModal, ssologin, setLoading } = useGlobalContext();
  console.log('SIGNINPAGE')
  const handleLogin = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      const email = document.getElementById('id').value;
      const password = document.getElementById('password').value;
      if (!email) {
        openModal('credentials');
        return;
      }
      if (!password) {
        openModal('credentials');
        return;
      }
      // call some login function
      login(email, password);
    },
    [login]
  );

  useEffect(() => {
    const submitOnEnter = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleLogin();
      }
    };

    const form = document.getElementById('signin');
    form.addEventListener('keypress', submitOnEnter);
    return () => {
      form.removeEventListener('keypress', submitOnEnter);
    };
  }, [handleLogin]);

  useEffect(() => {
    setLoading(true);
    const checkToken = async (token) => {
      await ssologin(token);
    };
    if (props.location.search) {
      const { ssotoken } = queryString.parse(props.location.search);
      if (ssotoken) {
        checkToken(ssotoken);
      }
    } else {
      if (!userAuthed) {
        setLoading(false)
      }
    }
    if (userAuthed) {
      props.history.push('/');
    }
  }, [userAuthed, props.history]);

  return (
    <div id="page">
      <div className="signin reset">
        <div className="logo-wrapper">
          <div className="hyatt icon-HYATT"></div>
          <div className="ideas icon-IDEAS"></div>
        </div>

        <form id="signin" className="form-wrapper">
          <div className="form-inner">
            <div className="form-title">
              <h2>Admin Login</h2>
            </div>
            <div className="form-item">
              <label htmlFor="">Hyatt Email</label>
              <input id="id" type="text" />
            </div>
            <div className="form-item">
              <label htmlFor="">Password</label>
              <input id="password" type="password" />
            </div>
            <div className="form-actions">
              <button className="big primary" onClick={handleLogin}>
                <span>Login</span>
              </button>
            </div>
          </div>

          <Link to="auth/forgot" className="forgot">
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
