const japanese = {
    // Menu
    Home: 'ホーム',
    All_Categories: 'すべてのカテゴリ',
    FNB: '飲食',
    FNB_Blank: '飲食',
    OPSHR: '操作, 人事',
    SNM: '営業本部',
    SNM_Blank: '営業本部',
    Search: '検索',
    Search_Place_Holder: 'キーワード検索',
    Series: 'シリーズ',
    Quizzes: 'クイズ',
    Speakers: 'スピーカ',
    Speaker: 'スピーカ',
    Leaderboard: 'リーダーボード',
    My_Profile: '個人のプロファイル',
    My_Lists: '個人のリスト',
    My_Quizzes: '個人のクイズ',
    Log_Out: 'ログアウト',

    // Home page
    WELCOME_BACK: 'おかえりなさい！',
    RECOMMENDED_FOR_YOU : 'おすすめ',
    Episodes: 'エピソード',
    //Quizzes(duplicated)
    INSPIRATION: 'インスピレーション',
    DIRECTION: 'ダイレクション',
    EXCELLENCE: '優秀',
    APPLICATION: 'アプリケーション',
    SOLUTION: 'ソリューション',
    Home_Page_Content: 'I.D.E.A.S 2023とは？毎週月曜日に公開される5分間の見やすい動画で、50人以上のリーダーから学び、インスピレーションを受けることができます。いつでも、どこにいても、自分のペースで視聴できます。客室、レストラン、バーをきちんと管理するためのクイズを含むエデュテインメント（教育的エンターテインメント）ツールです。販売テクニック、デジタル＆コミュニケーションのベストプラクティス。イベントの販売、企画、運営をより良くするためのソリューション。より良いリーダーになり、従業員を大切にしましょう。ハイアット アジアパシフィック ホテルのすべてのリーダーシップ委員会とチームに捧げます。',
    Countries: '国',
    Markets: 'マーケット',
    Hotels: 'ホテル',
    //Series(duplicated),
    //Episodes(duplicated),
    //Quizzes(duplicated),
    Users: 'ユーザー',
    We_Love_Mondays: '月曜日こと大好きです！',
    Viewed: '表示',
    Views: '表示',
    Load_More: 'さらにロード',
    Explore: 'もっと見る',
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    Trending_Series: 'トレンヂングシリーズ',
    Dig_Use_Share_Anywhere_Anytime: 'Dig, use & share\nanywhere, anytime',
    With: 'と ',
    Videos: ' ビデオ',
    Trending_Speakers: '人気のあるスピーカー',
    Discover_Series: 'シリーズを発見',
    //return by backend,
    //return by backend,
    //return by backend,
    Have_Suggestions_For_Improvements: '改善のための提案ありますか',
    Give_Us_Your_Ideas: 'アイディアをお待ちしています',
    Terms_And_Conditions: '利用規約',
    Focus: 'フォーカス',

    // EXPLORE ALL
    EXPLORE_THE_LIBRARY: 'ライブラリーを探索する',
    Search_By_Theme_Topi_Or_Speaker: 'キーワード検索',
    SERIES: 'シリーズ',
    EPISODES: 'エピソード',
    QUIZZES: 'クイズ',
    Watch_The_Series: 'シリーズを見る',
    START: 'スタート',
    RETAKE: 'もう一度',
    Discover_More: 'もっと知りたい',
    All: 'すべて',
    Loading: 'ローディング...',

    // CATEGORY
    //Series(duplicated),
    Search_For_Series: 'シリーズ検索',
    Latest_Series: '最新シリーズ',
    All_Series: '全シリーズ',
    View_Details: '詳細を表示',
    Watch: '見る',
    XEpisodes: ' エピソード',
    XEpisode: ' エピソード',
    Ready_For_A_Quiz: 'より多くのポイントを獲得するためのクイズの準備はできていますか？',
    Start: 'スタート',
    //Episodes(duplicated),
    //Search by theme, topic or speaker(duplicated),
    Sort_By: 'ソート',
    Default: 'デフォルト',
    Latest: '最新',
    Earliest: '最も早い',
    Most_Viewed: '最も閲覧',
    All_Episodes: '全てのエピソード',
    //Quizzes(duplicated),
    Search_For_Quizzes: 'クイズ検索',
    Recommended_For_You: 'あなたへのおすすめ',
    All_Quizzes: '全てのクイズ',
    //Watch_The_Series(duplicated),
    Re_take: 'もう一度',
    //Watch_The_Series(duplicated),
    //Start: '',
    Passed: '合格',
    Failed: '失敗',
    //Discover more(duplicated),

    // EPISODE PAGE
    //[X] Episodes(duplicated),
    Related_Series: '関連のシリーズ',
    Share_Video: 'シェア',
    Watch_Later: '後でみる',
    XView: ' ビュー',
    XViews: ' ビュー',
    //Speakers(duplicated),
    Released_On: 'リリース日 ',
    Discussion: 'ディスカッション',
    XComments: ' コメント',
    XComment: ' コメント',
    Reply: '返信',
    Enter_Your_Comment_Here: 'コメントを入力',
    Cancel: 'キャンセル',
    Submit: '提出する',
    Back_To_Top: 'トップに戻る',

    // LEADERBOARD
    Weekly_Leaderboard: '毎週リーダーボード',
    Expert_Viewer : 'エキスパートの視聴者',
    Biggest_Viewed: '過去1週間で最も多くのエピソードを視聴した人',
    Fastest_Viewer: '一番最初に視聴した人',
    Woke_Up_Earilest: '早起きして、その国で一番最初に視聴した人',
    Star_Contributor: '最も貢献した人',
    Posted_Greatest: 'コメントを投稿し、最大級の愛とケアを受けた人',

    // PROFILE PAGE
    EDIT_PROFILE: 'プロフィールを編集',
    SHOW_HISTORY: '履歴の表示',
    MY_QUIZ_HISTORY: 'クイズ履歴',
    RE_TAKE: 'クイズを受験する',
    BADGES: 'バッジ',
    //Expert_Viewer(duplicated)
    //Fatest_Viewer(duplicated)
    //Star_Contributor(duplicated)
    //QUIZZES(duplicated)
    PERFORMANCE: 'パフォーマンス',
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    Pts: 'ポイント',
    PROGRESS: '進展',
    MY_ACTIVITY: 'マイアクティビティ',
    MonthAndYear: '月、年',
    VIDEOS: 'ビデオ',
    QUIZZES_TAKEN: '受けたのクイズ',
    QUIZZES_PASSED: '合格したのクイズ',
    COMMENTS: 'コメント',
    // ???
    Videos_Watched: '視聴した動画',
    Quizzes_Taken: '受けたのクイズ',
    Comments: 'コメント',
    Week_1: '第1週',
    Week_2: '第2週',
    Week_3: '第3週',
    Week_4: '第4週',
    Week_5: '第5週',

    // NOTIFICATION
    Notification: '通知',
    Discover: 'デイスカバー',
    Take_The_Quiz: 'クイズを受ける',

    // MY LISTS
    WATCH_LATER: '後で見る',
    XVideos: ' ビデオ',
    XVideo: ' ビデオ',
    VIEWED: '表示',
    Yesterday: '昨日',
    // ??? 
    Clear: 'クリアー',
    Watc_Again: 'も一回見る',
    LIKED: '好かれた',
    //Xvideos(duplicated)
    // OTHER USER PROFILE
    Back: '戻る',
    Badges: 'バッジ',
    //Expert viewer(duplicated)
    //Fatest viewer(duplicated)
    //Star contributor(duplicated)
    //Quizzes(duplicated)
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    //Pts(duplicated)
    LIKED_VIDEOS: 'いいね！されたビデオ',
    No_Result_For_Your_Search: '検索結果に該当するものはありません',

    // QUIZ INTRODUCTION
    Do_You_Remember: '',
    XQuestions: ' 質問',
    //START(duplicated)
    NEXT: '次',
    PREVIOUS: '前',

    // QUIZ RESULT
    You_Pass_GIF: '',
    Your_Score_Is: 'スコア',
    GIF_PASSED: '',
    Retake: 'リテイク',
    GIF_FAILED: '',
    XPoints: ' ポイント',
    Xpoints: ' ポイント',
    Dont_Worry: '安心してください。どこを間違えたか確認し、質問を理解して正しい答えを見つけましょう。',
    Help_Me_Pass: '合格への手助け',
    //Retake(duplicated)
    Almost_There: 'もう少しです！',
    Help_Description: `<1>{{correctCount}} の正解があって、以下はあなたが犯した間違いです。</1> <2>ぜひ、チレンジしてください</2>`,
    Find_The_Correct_Answer: 'エピゾード <1>{{relatedEpisode}}</1> 中でソルーションを見つけて下さい',
    Learn_From_Series: 'こちらのシリーズをご参照下さい',
    Quizz_Welcome: "<1>{{title}} について一番重要な内容を覚えていますか？</1> <2> このクイズでご自身の知識を確認し、80/100点以上の点数を獲得してください。</2> <3> 一度目が不合格でも、合格するまで再チャレンジできますのでご安心ください。</3>",
    Xquestions: ' 質問',
    //Retake(duplicated)

    // Lost

    // Menu
    Explore_All: 'もっと見る',
    No_Result_Found: '検索結果に該当するものはありません',
    Take_The_Quizz: '',

    // Home page
    Play_Episode: 'プレイエピソード',
    Start_Quiz: 'クイズを始める',
    Visit_Now: '今すぐアクセスを',
    Certificates: '証明書',

    // Explore All
    Filter_By: 'スクリーニング',

    // Video / Episode
    In_Watch_List: 'ウォッチリストに登録中',
    Remove_From_Watchlist: 'ウォッチリストから削除',
    Link_Copied: 'ビデオのリンクがコピーされているので、仲間とシェアしてください',
    Xmins: '分',

    // Profile page
    Comments_Posted: '掲載されたのコメント',
    January: '1月',
    February: '2月',
    March: '3月',
    April: '4月',
    May: '5月',
    June: '6月',
    July: '7月',
    August: '8月',
    September: '9月',
    October: '10月',
    November: '11月',
    December: '12月',
    Jan: '1月',
    Feb: '2月',
    Mar: '3月',
    Apr: '4月',
    Jun: '6月',
    Jul: '7月',
    Aug: '8月',
    Sep: '9月',
    Oct: '10月',
    Nov: '11月',
    Dec: '12月',

    // Profile Edit page

    // My List page
    Today: '今日',
    A_Week_Ago: '1週間前',
    A_Month_Ago: '1か月前',

    // Quizz page
    There_Are_More: 'この質問にはもっと正しい答えがあります。もう一回見て下さい',

    // 404 page
    Not_Be_Found: '要求されたリソースが見つからない',
    Check_URL: 'URLを確認し、もう一度やり直してください',

    // Sustainability page
    sustainability: 'サステナビリティ',
    sustainability_solgan: '生ゴミを減らし、プラスチックゴミをなくし、環境のために行動する',
    sustainability_description: '健全な地球環境は、すべての人が最高の状態であるために不可欠です',

    //Update
    'Hotel_Statistics': 'ホテルの統計',
    'Directory': 'ディレクトリ',
    "Welcome_to_I.D.E.A.S": 'I.D.E.A.S 2024へようこそ! 毎週月曜日に公開される複数の見やすい5分間の動画で、60人以上のリーダーから学び、インスピレーションを受けてください。 いつでも、どこでも、自分のペースでストリーミングできます。 ルーム、レストラン、バーの効果的な管理をサポートするインタラクティブなクイズを備えたエデュテインメントツールです。 セールステクニック、デジタル、通信のベストプラクティスを学びましょう。セールスやイベントの計画や運営を改善するためのソリューションを学ぶこともできます。リーダーシップを向上させ、チームに貢献しましょう。 すべてのハイアットアジアパシフィックホテルのリーダーシップ委員会とそれぞれのチームに捧げます。',
    "HYATT": "ハイアット",
    "PHOTOGRAPHY": "写真撮影",
    "Dig_Use_&_Share": "探求して、利用して、共有しまし",
    "Anywhere_Anytime": "ょういつでも、どこでも",
    "MoodBoard": "ムードボード",
    "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "グローバルハイアット F&B写真撮影",
    "Leaderboard_Badges": "リーダーボード バッジ",
    "Show_Full_History": "完全な履歴を表示",
    "Average_of_users_have_watched...": "ユーザーの平均視聴回数は",
    "...videos": "動画です",
    "Average_of_users_have_taken...": "ユーザーの平均クイズ受験回数は",
    "...number_of_quizzes": "回です",
    "Average_of_users_have_passed...": "ユーザーの平均クイズ合格回数は",
    "...quizzes": "回です",
    "Image_upload": "画像のアップロード",
    "Enter_your_comment_here": "ここにコメントを入力してください",
    "Series_summary": "シリーズの概要",
    "Download": "ダウンロード",
    "Completions": "完了",
    passed: "passed",
    view: "（回）視聴",
    views: "（回）視聴",
    point: "ポイント",
    points: "ポイント",
}

export default japanese
