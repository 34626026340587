import React, { useState, useEffect } from 'react';
import { searchSeries, searchQuizzes, getSpeakers } from '../../utils/data';

const useNavSearch = (q, filter, category = null, startLoading = true) => {
  const QUIZZES_PAGESIZE = 8;
  const SERIES_PAGESIZE = 16;

  const [speakers, setSpeakers] = useState([]);
  const [speakersIsLoading, setSpeakersIsLoading] = useState(false);

  const [series, setSeries] = useState([]);
  const [seriesIsLoading, setSeriesIsLoading] = useState(false);

  const [quizzes, setQuizzes] = useState([]);
  const [quizzesIsLoading, setQuizzesIsLoading] = useState(false);

  const resetSpeakers = () => {
    setSpeakers([]);
    setSpeakersIsLoading(false);
  }

  const resetSeries = () => {
    setSeries([]);
    setSeriesIsLoading(false);
  }

  const resetQuizzes = () => {
    setQuizzes([]);
    setQuizzesIsLoading(false);
  }

  useEffect(() => {
    if (!startLoading) {
      return;
    }

    switch (filter) {
      case 'series':
        resetSpeakers();
        resetQuizzes();

        fetchSeries(0, true);
        break;

      case 'speakers':
        resetSeries();
        resetQuizzes();

        fetchSpeakers();
        break;

      case 'quizzes':
        resetSpeakers();
        resetSeries();

        fetchQuizzes();
        break;

      default:
        fetchSpeakers();
        fetchSeries();
        fetchQuizzes();
        break;
    }
  }, [q, filter, category, startLoading]);

  async function fetchSpeakers() {
    if (speakersIsLoading) return;
    setSpeakersIsLoading(true);

    let params = {};

    if (q !== '') {
      params = {q}
    }
    let SPE = await getSpeakers(params);

    if (SPE) {
      SPE.data = SPE.data?.sort((a, b) => {
        const surnameA = a.surname?.toUpperCase(); // ignore upper and lowercase
        const surnameB = b.surname?.toUpperCase(); // ignore upper and lowercase
        if (surnameA < surnameB) {
          return -1;
        }
        if (surnameA > surnameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      SPE.data.map(speaker => {
        speaker.name = `${speaker.name} ${speaker.surname}`;
      })

      setSpeakers(SPE.data);
    } else {
      setSpeakers([]);
    }

    setSpeakersIsLoading(false);
  }

  async function fetchSeries() {
    if (seriesIsLoading) return;
    setSeriesIsLoading(true);

    let SER = await searchSeries({
      category,
      q,
      range: `[0, ${SERIES_PAGESIZE - 1}]`,
    })

    if (SER) {
      setSeries(SER.data);
    } else {
      setSeries([]);
    }

    setSeriesIsLoading(false);
  }

  async function fetchQuizzes() {
    if (quizzesIsLoading) return;
    setQuizzesIsLoading(true);

    let QUI = await searchQuizzes({
      category,
      q,
      range: `[0, ${QUIZZES_PAGESIZE - 1}]`,
    });

    if (QUI) {
      setQuizzes(QUI.data);
    } else {
      setQuizzes([]);
    }

    setQuizzesIsLoading(false);
  }

  return {
    speakers: {
      list: speakers,
      isLoading: speakersIsLoading,
    },
    series: {
      list: series,
      isLoading: seriesIsLoading,
    },
    quizzes: {
      list: quizzes,
      isLoading: quizzesIsLoading,
    },
  }
}

export default useNavSearch;