import React, { useState, useRef } from "react";
import { postImageComment } from "../../utils/data";

const ImageCommentsButton = ({ imgComment, setImgComment }) => {
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleFiles = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size >= 30 * 1024 * 1024) {
        alert("File size should be less than 30MB");
        return
    }

    // setImgComment(file)
    setLoading(true);
    postImageComment(file).then((res) => {
      setImgComment(res?.data);
      setLoading(false);
    });
  };

  return (
    <div className="image-comments-button">
      {!!loading && (
        <span className="loading">
          Loading...
        </span>
      )}
      {!!imgComment && !loading && (
        <>
          <div className="close-button" onClick={() => setImgComment(undefined)}>
            x
          </div>
          <span className="image-name">
            {imgComment.name.length > 20
              ? imgComment.name.substring(0, 20) + "..."
              : imgComment.name}
          </span>
        </>
      )}
      <div className="action-wrapper">
        <img src="/assets/images/icon-img.svg" alt="" />
        <input
          ref={inputFile}
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleFiles}
          onClick={(event) => (event.target.value = null)}
        />
      </div>
    </div>
  );
};

export default ImageCommentsButton;
