import React, { useState, useCallback } from 'react';
import { useGlobalContext } from '../../../store/global';
import { useDropzone } from 'react-dropzone';
import { uploadPhoto } from '../../../utils/data';

export default function PhotoUpload() {
  const { openModal, closeModal, canCloseModal } = useGlobalContext();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);

  const backToReg = (e) => {
    e.preventDefault(e);
    openModal('registration', false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      setFileSrc(res);
    };
    reader.readAsDataURL(acceptedFiles[0]);
    setUploadedFile(acceptedFiles[0]);
  }, []);

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (uploadedFile) {
      const fd = new FormData();
      fd.append('file', uploadedFile);
      uploadPhoto(fd);
      openModal('announcement');
      closeModal();
    } else {
      openModal('announcement');
      return;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="popup-inner">
      <div className="popup-title">
        <h2>Please complete your profile to continue</h2>
        <div className="close icon-x" onClick={canCloseModal ? closeModal : null}></div>
      </div>

      <div className="popup-content">
        <form action="">
          <div className="form-item">
            <div className="file-upload">
              <h2 style={{ marginBottom: '0' }}>Choose your nicest photo</h2>
              <h2 style={{ fontSize: '22px' }}>(optional)</h2>
              <div
                className="img-wrapper"
                {...getRootProps()}
                style={{
                  backgroundImage: `url(${!fileSrc ? '/assets/images/profile.png' : fileSrc})`,
                }}
              >
                <img id="output" border="0" />
              </div>
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                style={{ display: 'none' }}
                {...getInputProps()}
              />
              <label htmlFor="file" style={{ cursor: 'pointer' }} className="upload">
                {' '}
                <span className="icon-upload"></span> Upload Image
                <div className="upload-limit">(Limit 20mb)</div>
              </label>
            </div>
          </div>
          <div className="form-actions">
            <button className="secondary big" onClick={backToReg}>
              back
            </button>
            <button className="primary big" onClick={handleFileUpload}>
              save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
