import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../../store/global';
import { getAnnouncement, seeAnnouncement } from '../../../utils/data';
import get from 'lodash.get';

export default function Announcement(props) {
  const { closeModal } = useGlobalContext();

  const [state, setState] = useState({
    data: null,
  });

  const see = () => {
    seeAnnouncement();
    closeModal();
  };

  useEffect(() => {
    getAnnouncement().then((res) => {
      if (res.data && res.data.length === 0) {
        closeModal();
        return;
      }
      setState((prevState) => ({
        ...prevState,
        data: res.data[0],
      }));
    });
  }, []);

  return state.data ? (
    <div className='new-popup-inner'>
      <div className='new-popup-title'>
        <div />
        <h2><span className="icon-bell"></span> Announcement</h2>
        <div className="close icon-x" onClick={see}></div>
      </div>
      <div>

        {get(state, 'data.type', '') === 'text' && (
          <div className='new-anouncement-text'>
            <h2>{get(state, 'data.title', '')}</h2>
            <p dangerouslySetInnerHTML={{ __html: get(state, 'data.message', '')}} />
          </div>
        )}

        {(get(state, 'data.type', '') === 'image')  && (
          !get(state, 'data.link', '') ?
            <img
              className='new-anouncement-img'
              src={`${process.env.REACT_APP_IMG_URL}/storage/announcement-picture/${get(state, 'data.announcementImage.uri', '')}`}
              alt="announcement"
            />
          :
            <a href={get(state, 'data.link', '')} target='_blank' rel="noopener noreferrer">
              <img
                className='new-anouncement-img'
                src={`${process.env.REACT_APP_IMG_URL}/storage/announcement-picture/${get(state, 'data.announcementImage.uri', '')}`}
                alt="announcement"
              />
            </a>
        )}
      </div>
    </div>
  ) : null;
}
