import React from 'react';


export default function Banner1() {
  return (
    <>
      <section className="advertising-desk">
        <div className="viewport">
          <div className="inner">
            <div className="wrapper">
              <a href="https://hyattdigitalexhibition.com" target="_blank">
                <img src="/assets/images/ad-desk-text.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="advertising-mob">
        <div className="viewport">
          <div className="inner">
            <div className="wrapper">
              <a href="https://hyattdigitalexhibition.com" className="cover" target="_blank"></a>
              <img src="/assets/images/ad-mob-text.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
