import React from 'react';
import { withRouter } from 'react-router';

const HomeVideo = (props) => {
  const { id } = props;

  return (
    <div className="player-wrapper">
      {/* <div className="cover" style={{ cursor: 'default' }}></div> */}
      {id && (
        <iframe
          src={`//fast.wistia.net/embed/iframe/${id}?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
          frameBorder="0"
          scrolling="no"
          className="video-home"
          name="hero_embed"
        ></iframe>
      )}
    </div>
  );
};

export default withRouter(HomeVideo);
