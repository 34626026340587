import React, {useEffect, useState} from 'react';
import HomeVideo from './homeVideo';
import { Link } from "react-router-dom";
import get from "lodash.get";
import { useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

export default function HomeHero(props) {
  const { heroData } = props;
  const [currentWistiaHashId, setCurrentWistiaHashId] = useState(null);
  const [currentType, setCurrentType] = useState("video");
  const [current, setCurrent] = useState({});
  const [videos, setVideos] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); // For mobile version
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const { t } = useTranslation();

  window.addEventListener("resize", function() {
    setInnerWidth(window.innerWidth);
  })

  useEffect(() => {
    if (currentWistiaHashId == null) {
      setCurrentWistiaHashId(heroData.video);
    }
    setVideos(heroData.recommendedVideos);
    setQuizzes(heroData.recommendedQuizzes);
  })

  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  return (
    <section className="home-header">
      <div className="viewport">
        <div className="inner">
          {/*<div className="txt-wrapper">*/}
          {/*  <div className="home-header-ideas">*/}
          {/*    <p className="home-header-ideas__word">*/}
          {/*      INSPIRATION*/}
          {/*    </p>*/}
          {/*    <p className="home-header-ideas__word">*/}
          {/*      DIRECTION*/}
          {/*    </p>*/}
          {/*    <p className="home-header-ideas__word">*/}
          {/*      EXCELLENCE*/}
          {/*    </p>*/}
          {/*    <p className="home-header-ideas__word">*/}
          {/*      APPLICATION*/}
          {/*    </p>*/}
          {/*    <p className="home-header-ideas__word">*/}
          {/*      SOLUTION*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  /!* <h2 style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '390px'}}>*/}
          {/*    <span style={{ color: 'grey', marginLeft: '4px'}}>Inspiration </span>*/}
          {/*    <span style={{ color: 'blue', marginLeft: '4px'}}>Direction </span>*/}
          {/*    <span style={{ color: '#FF7DD7', marginLeft: '4px'}}>Excellence </span>*/}
          {/*    <span style={{ color: '#76BD87', marginLeft: '4px'}}>Application </span>*/}
          {/*    <span style={{ color: '#ff6600', marginLeft: '4px'}}>Solution</span>*/}
          {/*  </h2> *!/*/}
          {/*  <p>{heroData.text}</p>*/}
          {/*</div>*/}
          {/*<HomeVideo id={heroData.video} />*/}
          <div className="player-wrapper desktop">
            {currentType == 'video' ?
              (
                currentWistiaHashId == heroData.video ? (
                  <>
                    <div className="cover"></div>
                    {innerWidth >= 1024 && (
                      <iframe
                        src={`//fast.wistia.net/embed/iframe/${currentWistiaHashId}?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                        frameBorder="0"
                        scrolling="no"
                        className="video-home"
                        name="hero_embed"
                      ></iframe>
                    )}
                  </>
                ) : (
                  <Link to={`/video?id=${current.id}`}>
                    {get(current, "videoThumbnail.uri", "") !== "" && (
                      <div
                        className="video-home"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage:
                            get(current, "videoThumbnail.uri", "") !== ""
                              ? `url(${
                                process.env.REACT_APP_IMG_URL
                              }/storage/video-thumbnail/${get(
                                current,
                                "videoThumbnail.uri",
                                ""
                              )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    {get(current, "videoThumbnail.uri", "") === "" && (
                      <iframe
                        src={`//fast.wistia.net/embed/iframe/${currentWistiaHashId}?autoplay=false&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                        frameBorder="0"
                        scrolling="no"
                        className="video-home"
                        name="hero_embed"
                      ></iframe>
                    )}
                    <div className="cover"></div>
                    <div className="play-button">
                      <div className="icon"><span className="icon-playbuttonbold"></span></div>
                      <div className="text">{t('Play_Episode')}</div>
                    </div>
                  </Link>
                )
              ) : (
                <Link to={`/quiz?id=${current.id}`}>
                  {get(current, "thumbnail.uri", "") !== "" && (
                    <div
                      className="video-home"
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage:
                          get(current, "thumbnail.uri", "") !== ""
                            ? `url(${process.env.REACT_APP_IMG_URL
                            }/storage/quiz-thumbnail/${get(
                              current,
                              "thumbnail.uri",
                              ""
                            )})`
                            : 'url("/assets/images/video-1.jpg")',
                      }}
                    ></div>
                  )}
                  <div className="cover"></div>
                  <div className="play-button quiz">
                    <img className="icon-quiz" src="/assets/images/quiztime.png"></img>
                    <div className="text">{t('Start_Quiz')}</div>
                  </div>
                </Link>
              )
            }
          </div>
          <Swiper
            className="player-wrapper mobile"
            onSwiper={(swiper) => initSwiper(swiper)}
            spaceBetween={8}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            <SwiperSlide key="welcome-video">
              <div className="media-container">
                {/* <div className="cover"></div> */}
                {innerWidth < 1024 && activeIndex == 0 && (
                  <iframe
                    src={`//fast.wistia.net/embed/iframe/${heroData.video}?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                    frameBorder="0"
                    scrolling="no"
                    className="video-home"
                    name="hero_embed"
                  ></iframe>
                )}
              </div>
            </SwiperSlide>
            {videos?.map((v, i) => (
              <SwiperSlide key={v.id}>
                <Link
                  to={`/video?id=${v.id}`}
                  style={{ color: "#d2d2d2", textDecoration: "none" }}
                >
                  <div className="media-container">
                    {get(v, "videoThumbnail.uri", "") !== "" && (
                      <div
                        className="video-home"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage:
                            get(v, "videoThumbnail.uri", "") !== ""
                              ? `url(${
                                process.env.REACT_APP_IMG_URL
                              }/storage/video-thumbnail/${get(
                                v,
                                "videoThumbnail.uri",
                                ""
                              )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    {get(v, "videoThumbnail.uri", "") === "" && (
                      <iframe
                        src={`//fast.wistia.net/embed/iframe/${v.wistiaHashId}?autoplay=false&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                        frameBorder="0"
                        scrolling="no"
                        className="video-home"
                        name="hero_embed"
                      ></iframe>
                    )}
                    <div className="cover"></div>
                    <div className="play-button">
                      <div className="icon"><span className="icon-playbuttonbold"></span></div>
                      <div className="text">{t('Play_Episode')}</div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
            {quizzes?.map((v, i) => (
              <SwiperSlide key={v.id}>
                <Link
                  to={`/quiz?id=${v.id}`}
                  style={{ color: "#d2d2d2", textDecoration: "none" }}
                >
                  <div className="media-container">
                    {get(v, "thumbnail.uri", "") !== "" && (
                      <div
                        className="video-home"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage:
                            get(v, "thumbnail.uri", "") !== ""
                              ? `url(${process.env.REACT_APP_IMG_URL
                              }/storage/quiz-thumbnail/${get(
                                v,
                                "thumbnail.uri",
                                ""
                              )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    <div className="cover"></div>
                    <div className="play-button quiz">
                      <img className="icon-quiz" src="/assets/images/quiztime.png"></img>
                      <div className="text">{t('Start_Quiz')}</div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev swiper-button-disabled"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
          <div className="option-wrapper">
            <div
              className={"welcome desktop" + (currentWistiaHashId == heroData.video ? " active" : "")}
              onClick={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
              }}
              onMouseEnter={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
              }}
            >
              <img src={"/assets/images/welcome-back-active.png"}></img>
            </div>
            <div
              className="welcome mobile"
              onClick={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
                setActiveIndex(0);
              }}
            >
              <img src={"/assets/images/welcome-back-mini-" + (activeIndex == 0 ? "active.png" : "inactive.png")}></img>
            </div>
            <div className="recommendation desktop">
              <h3>{t('Recommended_For_You')}</h3>

              <div className="categories">
                <h3>{t('Episodes')}</h3>

                {videos?.map(v => (
                  <Link
                    className={"category" + (current == v ? " active " + v.categoryShorthand : "")}
                    onMouseEnter={(e) => {
                      setCurrentWistiaHashId(v.wistiaHashId);
                      setCurrentType('video');
                      setCurrent(v);
                    }}
                    to={`/video?id=${v.id}`}
                  >
                    <div className="category-wrapper">
                      <div className={"category__name " + v.categoryShorthand}>{v.categoryName}</div>
                      {v.isSustainability && (
                        <div className="sustainability-tag-wrapper">
                          <span className="icon-sustainability"></span>
                          <span className="text-sustainability">sustainability</span>
                        </div>
                      )}
                    </div>
                    <div className={"title" + (current == v ? " " + v.categoryShorthand : "")}>{v.title}</div>
                  </Link>
                ))}
              </div>
              <div className="categories">
                <h3>{t('Quizzes')}</h3>

                {quizzes?.map(q => (
                  <Link
                    className={"category" + (current == q ? " active " + q.categoryShorthand : "")}
                    onMouseEnter={(e) => {
                      setCurrentWistiaHashId('');
                      setCurrentType('quiz');
                      setCurrent(q);
                    }}
                    to={`/quiz?id=${q.id}`}
                  >
                    <div className={"category__name " + q.categoryShorthand}>{q.categoryName}</div>
                    <div className={"title" + (current == q ? " " + q.categoryShorthand : "")}>{q.title}</div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="recommendation mobile">
              <div className="categories">
                {videos?.map(v => (
                  <Link
                    className={"category" + (activeIndex == v.index ? " active " + v.categoryShorthand : "")}
                    to={`/video?id=${v.id}`}
                  >
                  </Link>
                ))}
              </div>
              <div className="categories">
                {quizzes?.map(q => (
                  <Link
                    className={"category" + (activeIndex == q.index ? " active " + q.categoryShorthand : "")}
                    to={`/quiz?id=${q.id}`}
                  >
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="option-wrapper mobile">
            <div className="welcome">
              {activeIndex == 0 && (
                <img src="/assets/images/welcome-back-text-active.png"></img>
              )}

              {activeIndex != 0 && (
                videos?.map(v => (
                  <div className={"category " + v.categoryShorthand} style={{ display: (activeIndex == v.index ? 'block' : 'none')}}>
                    <div className="category__name">{v.categoryName}</div>
                    <div className="title">{v.title}</div>
                    {v.isSustainability && (
                      <div className="sustainability-tag-wrapper">
                        <span className="icon-sustainability"></span>
                        <span className="text-sustainability">sustainability</span>
                      </div>
                    )}
                  </div>
                ))
              )}
              {activeIndex != 0 && (
                quizzes?.map(q => (
                  <div className={"category " + q.categoryShorthand} style={{ display: (activeIndex == q.index ? 'block' : 'none')}}>
                    <div className="category__name">{q.categoryName}</div>
                    <div className="title">{q.title}</div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
