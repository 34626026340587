import React, { useEffect, useState } from 'react';
import { getCommentLikes } from '../../../utils/data'
import { useGlobalContext } from '../../../store/global';
import get from 'lodash.get';

const Likes = () => {
    const { closeModal, modalData } = useGlobalContext();
    const [likesList, setLikesList] = useState([]);

    useEffect(() => {
        getCommentLikes(modalData).then(res => setLikesList(res.data))
    }, [])


    return (
        <div className="popup-inner thanks" style={{ maxWidth: '650px' }}>
            <div className="popup-title">
                <h2/>
                <div className="close icon-x" onClick={closeModal}></div>
            </div>

            <div className="popup-content thanks-content">
                {likesList.map((like, index) => {
                    if (!like)
                        return null;

                    const name = `${get(like, 'name', '')} ${get(like, 'surname', '')}`
                    const city = get(like, 'city.name', '')
                    const country = get(like, 'country.name', '')
                    const brand = get(like, 'hotel.name', '')
                    const url = get(like, 'profilePicture.uri', '')

                    const backgroundImage = url ? `url(${process.env.REACT_APP_IMG_URL}/storage/user-picture/${url})` : `url(/assets/images/profile.png)`

                    return (
                        <div className="item">
                            <div
                                className="avatar"
                                style={{ backgroundImage }}
                            />
                            <div className="right">
                                <span className="name">{name}</span>
                                <span className="city">{`
                                ${city}
                                ${city && country ? ', ': ''}
                                ${country}
                                ${(city || country) && brand ? ' - ' : ''}
                                ${brand}`}{'.'}<div/></span>
                            </div>
                        </div>
                    )
                })}         
            </div>
        </div>
    )
}

export default Likes