import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'

const NavCategoriesSelector = ({
  pageClicked,
  isMobile,
  parentModalClick,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (!ref.current.contains(event.target)) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document.getElementById("categories-content" + (isMobile ? "-mobile" : "")).classList.remove("tab-opened");
        document.getElementById("categories-tab" + (isMobile ? "-mobile" : "")).classList.remove("active");
      } else {
        document.getElementById("categories-content" + (isMobile ? "-mobile" : "")).classList.add("tab-opened");
        document.getElementById("categories-tab" + (isMobile ? "-mobile" : "")).classList.add("active");
        return true;
      }
      return false;
    });
  };

  return (
    <div
      id={"categories-tab" + (isMobile ? "-mobile" : "")}
      className={"tab-select-wrapper categories-select-wrapper burger " + (isMobile ? "" : "hidden-md ")}
    >
      <div className="tab-name" onClick={handleClick}>
        {t('Directory')}
        <span className="icon-chevronright2"></span>
      </div>

      <div
        id={"categories-content" + (isMobile ? "-mobile" : "")}
        className="tab-select"
        ref={ref}
      >
        <Link
          className="tab"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/directory");
          }}
          to="/directory"
        >
          <img src="/assets/images/directory.png"></img>
          <span>{t('Directory')}</span>
        </Link>
        <Link
          className="tab"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/category", "F&B");
          }}
          to="/category/fb"
        >
          <img src="/assets/images/logo-fnb.png"></img>
          <span>{t('FNB')}</span>
        </Link>
        <Link
          className="tab"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/category", "OPS|HR");
          }}
          to="/category/operations"
        >
          <img src="/assets/images/logo-ope.png"></img>
          <span>{t('OPSHR')}</span>
        </Link>
        <Link
          className="tab"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/category", "S&M");
          }}
          to="/category/sm"
        >
          <img src="/assets/images/logo-snm.png"></img>
          <span>{t('SNM')}</span>
        </Link>
      </div>
    </div>
  );
};

export default NavCategoriesSelector;
