import React from 'react';
import { useGlobalContext } from '../../../store/global';

export default function Thanks() {
  const { closeModal, modalData } = useGlobalContext();

  return (
      <div className="popup-inner delete-comment" style={{ maxWidth: '650px' }}>
        <div className="popup-title">
            <h2>Please confirm</h2>
            <div className="close icon-x" onClick={closeModal}></div>
        </div>

        <div className="popup-content thanks-content">
          <p>Are you sure you want to delete your comment?</p>
          <div className="actions">
            <div className="confirm" onClick={closeModal}>Cancel</div>
            <div className="delete" onClick={() => {
              modalData()
              closeModal()
            }}>Delete</div>
          </div>   
        </div>
      </div>
  );
}
