const http = (function () {
  const axios = require('axios');
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost',
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 500) {
        // window.location.pathname = '/notfound';
      }
      if (error.response && error.response.status === 403) {
        localStorage.clear();
        window.location.href = process.env.REACT_APP_SSO_URL;
      }
    }
  );

  return {
    get: function (url, config = {}) {
      return instance.get(url, config);
    },
    post: function (url, data, config = {}) {
      return instance.post(url, data, config);
    },
    put: function (url, data, config = {}) {
      return instance.put(url, data, config);
    },
    delete: function (url, config = {}) {
      return instance.delete(url, config);
    }
  };
})();

export default http;
