import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import Rotate from './rotate';
import Loader from './loader';
import Header from './header';
import Footer from './footer';

const Layout = (props) => {
  const { location } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {location.pathname.indexOf('auth') === -1 && <Header />}
      <div className="layout">
        {props.children}
        {location.pathname.indexOf('auth') === -1 && <Footer />}
      </div>
      <Loader />
      <Rotate />
    </>
  );
};

export default withRouter(Layout);
