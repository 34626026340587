import React from 'react';

const BannerVideo = (props) => {
  const { id } = props;

  return (
    <div className="player-wrapper">
      {/* <div className="cover" style={{ cursor: 'default' }}></div> */}
      {id && (
        <iframe
          src={`//fast.wistia.net/embed/iframe/${id}?autoPlay=true&endVideoBehavior=loop&muted=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
          frameBorder="0"
          scrolling="no"
          className="video-home"
          name="banner_embed"
          allowFullScreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      )}
    </div>
  );
};

export default BannerVideo;
