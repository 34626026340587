import React, { useState, useEffect } from 'react';
import Wrapper from '../wrapper';
import HomeHero from './homeHero';
import Stats from './stats';
import SeriesSlider from '../../ui/seriesSlider';
import VideoList from './videoList';
import TrendingSpeakers from './trendingSpeakers';
import HdeBanner from './hdeBanner';
import { Link } from 'react-router-dom';
import { parseCategoryID, sortByCreated, sortByReleased } from '../../../utils';
import tracking from "../../../utils/tracking";
import {
  getHomePage,
  getTags,
  searchVideos,
  getLeaderboard,
  getProfile,
  getRecommendedQuizzes,
  getRecommendedVideos,
} from '../../../utils/data';
// import { nanoid } from 'nanoid'
import { useGlobalContext } from '../../../store/global';
import get from 'lodash.get';
import { isIE } from 'react-device-detect';
import { useTranslation } from 'react-i18next'
import JSMpeg from '@cycjimmy/jsmpeg-player';
import HyattPhotography from './hyattPhotography';
import HyattClassics from './hyattClassics';

const Home = (props) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang') || 'english';

  const { setLoading } = useGlobalContext();

  const [pageData, setPageData] = useState({
    heroData: {},
    hdeData: {},
    stats: {},
    trendingVideos: [],
    leaderboard: {},
    categories: [],
    selectedTag: null,
    recentFB: [],
    recentOps: [],
    recentSM: [],
    speakers: [],
    // allVideos: [],
    displayedVideos: [],
    discoverDisplay: 0,
    isFetchingVideos: false,
    canDisplayMore: true,
    tags: [],
    user: {},
  });

  useEffect(() => {
    if (!isIE) {
      getPageData();
    }
    if (isIE) {
      getPageDataIE();
    }
  }, []);

  useEffect(() => {
    const player = initSustainabilityVideo();
    window.addEventListener('scroll', () => scrollToTop(player));
  }, []);

  const scrollToTop = (player) => {
    // Get visiable window height
    var domHight = window.screen.height || window.innerHeight ||
      document.documentElement.clientHeight;
    // console.log('domHight', domHight);
    // Get dom scroll position
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    // Get video player
    var sv;
    // Get video height
    var scrollHeight;
    // Get video offsetTop
    var offsetTop;
    // Get video offsetTop - window height 
    var top;
    // Get video offsetBottom + video height
    var bottom;

    sv = document.getElementById(`sustainability-video`);
    if (sv) {
      scrollHeight = sv.scrollHeight;
      offsetTop = sv.offsetTop;
      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
      bottom = scrollHeight + offsetTop;
      if (scrollTop >= top && scrollTop <= bottom) {
        playSustainabilityVideo(player);
      } else {
        // console.log('sustainability video play not player yet');
      }
    }
  };

  const initSustainabilityVideo = () => {
    let player = new JSMpeg.VideoElement('#sustainability-video', 'assets/videos/sustainability-worldofcare.ts',
    {
      loop: true,
      autoplay: false,
      control: false,
      canvas: document.querySelector('#sustainability-canvas'),
      picMode: true,
    },
    {
      audio: false
    });
    return player;
  };

  const playSustainabilityVideo = (player) => {
    player.play();
    // console.log(player)
    // console.log('sustainability video play');
  };

  const getPageDataIE = async () => {
    const home = await getHomePage();
    const t = await getTags();
    const fb = await searchVideos({ category: '60596acbfbd25e709cd3cde1' }); // fb
    const ops = await searchVideos({ category: '60596acbfbd25e709cd3cddf' }); // ops
    const sm = await searchVideos({ category: '60596acbfbd25e709cd3cde0' }); // sm
    // const all = await searchVideos();
    const lb = await getLeaderboard();
    const p = await getProfile();

    const rv = await getRecommendedVideos();
    const rq = await getRecommendedQuizzes();

    rv.data.map(video => {
      video.categoryShorthand = parseCategoryID(video.category).categoryShorthand;
      video.categoryName = parseCategoryID(video.category).categoryName;
    })

    rq.data.map(quiz => {
      quiz.categoryShorthand = parseCategoryID(quiz.category).categoryShorthand;
      quiz.categoryName = parseCategoryID(quiz.category).categoryName;
    })

    const userCountryId = get(user, 'country.id', '');
    const leaderBoardRegion = get(leaderboard, 'fastestViewers', []).filter(
      (set) => set.country.id === userCountryId
    )[0];

    const homeData = home.data[0];
    const tags = t.data;
    const recentFB = fb.data;
    const recentOps = ops.data;
    const recentSM = sm.data;
    // const allVideos = all.data;
    const leaderboard = lb.data;
    const user = p.data;

    setPageData((prevState) => ({
      ...prevState,
      heroData: {
        header: homeData.header[lang],
        text: homeData.description[lang],
        title: homeData.title[lang],
        video: homeData.wistiaHashId,
        recommendedVideos: rv.data,
        recommendedQuizzes: rq.data,
      },
      stats: {
        countries: homeData.statisticCountries,
        hotels: homeData.statisticHotels,
        users: homeData.statisticUsers,
        videos: homeData.statisticVideos,
        episodes: homeData.statisticEpisodes,
        // quizzes: homeData.statisticQuizzesSubmit,
        quizzes: homeData.statisticQuizzesPast,
        text: homeData.description[lang],
      },
      hdeData: {
        title: homeData.hdePromoTitle,
        desc: homeData.hdePromoDescription,
        image: homeData.hdePromoImage,
        video: homeData.hdePromoWistia,
      },
      leaderboard,
      leaderBoardRegion,
      tags,
      trendingSeries: homeData.trendingSeries,
      trendingSpeakers: homeData.trendingSpeakers,
      recentFB,
      recentOps,
      recentSM,
      // allVideos,
      displayedVideos: organizeColumns(recentFB, recentOps, recentSM),
      user,
    }));
  };

  const getPageData = async () => {
    const filters = {
      range: `[0, 0]`,
      filter: { releaseDate: {$lte: new Date()} },
      // sort: { createdAt: -1 },
      sort: '["releaseDate","DESC"]',
    }

    setLoading(true);
    const [home, t, fb, ops, sm, /*all,*/ lb, p, rv, rq] = await Promise.all([
      getHomePage(),
      getTags(),
      searchVideos({...filters, category: process.env.REACT_APP_FB }), // fb
      searchVideos({...filters, category: process.env.REACT_APP_OPERATIONS }), // ops
      searchVideos({...filters, category: process.env.REACT_APP_SM }), // sm
      // searchVideos(),
      getLeaderboard(),
      getProfile(),
      getRecommendedVideos(),
      getRecommendedQuizzes()
    ]);

    rv.data.map((video, index) => {
      video.categoryShorthand = parseCategoryID(video.category).categoryShorthand;
      video.categoryName = parseCategoryID(video.category).categoryName;
      video.index = index + 1;
    })

    rq.data.map((quiz, index) => {
      quiz.categoryShorthand = parseCategoryID(quiz.category).categoryShorthand;
      quiz.categoryName = parseCategoryID(quiz.category).categoryName;
      quiz.index = rv.data.length + index + 1;
    })

    setLoading(false);
    const homeData = home.data[0];
    const tags = t.data;
    const recentFB = fb.data.sort(sortByReleased);
    const recentOps = ops.data.sort(sortByReleased);
    const recentSM = sm.data.sort(sortByReleased);
    // const allVideos = all.data.sort(sortByReleased);
    const leaderboard = lb.data;
    const user = p.data;
    const userCountryId = get(user, 'country.id', '');
    const leaderBoardRegion = get(leaderboard, 'fastestViewers', []).filter(
      (set) => get(set, 'country.id', null) === userCountryId
    )[0];

    setPageData((prevState) => ({
      ...prevState,
      heroData: {
        header: homeData.header[lang],
        text: homeData.description[lang],
        title: homeData.title[lang],
        video: homeData.wistiaHashId,
        recommendedVideos: rv.data,
        recommendedQuizzes: rq.data,
      },
      stats: {
        countries: homeData.statisticCountries,
        hotels: homeData.statisticHotels,
        users: homeData.statisticUsers,
        series: homeData.statisticSeries,
        episodes: homeData.statisticEpisodes,
        // quizzes: homeData.statisticQuizzesSubmit,
        quizzes: homeData.statisticQuizzesPast,
        text: homeData.description[lang],
      },
      hdeData: {
        title: homeData.hdePromoTitle,
        desc: homeData.hdePromoDescription,
        image: homeData.hdePromoImage,
        video: homeData.hdePromoWistia,
      },
      leaderboard,
      leaderBoardRegion,
      tags,
      trendingSeries: homeData.trendingSeries,
      trendingSpeakers: homeData.trendingSpeakers,
      recentFB,
      recentOps,
      recentSM,
      // allVideos,
      displayedVideos: organizeColumns(recentFB, recentOps, recentSM),
      user,
    }));
  };

  const organizeColumns = (fb, ops, sm) => {
    // organize videos so they line up in columns by category
    const organizedVideos = [];
    let i = 0;
    while (true) {
      if (fb[i]) {
        organizedVideos.push(fb[i]);
      }
      if (ops[i]) {
        organizedVideos.push(ops[i]);
      }
      if (sm[i]) {
        organizedVideos.push(sm[i]);
      }
      if (!fb[i] && !ops[i] && !sm[i]) {
        return organizedVideos;
      }
      i++;
    }
  };

  const changeTag = async (id) => {
    let videosToDisplay = [];
    if (id === null) {
      if (!isIE) {
        const [fb, ops, sm] = await Promise.all([
          searchVideos({ category: process.env.REACT_APP_FB }), // fb
          searchVideos({ category: process.env.REACT_APP_OPERATIONS }), // ops
          searchVideos({ category: process.env.REACT_APP_SM }), // sm
        ]);
        videosToDisplay = organizeColumns(
          fb.data.sort(sortByReleased),
          ops.data.sort(sortByReleased),
          sm.data.sort(sortByReleased)
        );
      }
    } else {
      const tagSearch = await searchVideos({ tags: id }); // sm
      tagSearch.data.sort(sortByCreated);
      videosToDisplay = tagSearch.data;
    }
    setPageData((prevState) => ({
      ...prevState,
      selectedTag: id,
      displayedVideos: videosToDisplay,
    }));
  };

  const showMoreVideos = async () => {
    if (pageData.isFetchingVideos) return;
    setPageData((prevState) => ({...prevState, isFetchingVideos: true}));
  
    const filters = {
      range: `[${pageData.discoverDisplay + 1}, ${pageData.discoverDisplay + 1}]`,
      filter: { releaseDate: {$lte: new Date()} },
      // sort: { createdAt: -1 },
      sort: '["releaseDate","DESC"]',
    }

    const [fb, ops, sm] = await Promise.all([
      searchVideos({...filters, category: process.env.REACT_APP_FB }), // fb
      searchVideos({...filters, category: process.env.REACT_APP_OPERATIONS }), // ops
      searchVideos({...filters, category: process.env.REACT_APP_SM }), // sm
    ]);

    if (!fb.data?.length || !ops.data?.length || !sm.data?.length) {
      setPageData((prevState) => ({
        ...prevState,
        canDisplayMore: false,
        isFetchingVideos: false,
      }))
      return;
    }

    setPageData((prevState) => ({
      ...prevState,
      displayedVideos: [...prevState.displayedVideos, ...fb.data, ...ops.data, ...sm.data],
      discoverDisplay: prevState.discoverDisplay + 1,
      isFetchingVideos: false,
    }));
  };

  const pageClicked = (page, value) => {
    tracking.event("home", page, value);
  }

  return (
    <Wrapper>
      <div id="content">
        <HomeHero heroData={pageData.heroData} />
        <Stats key={Math.random()} stats={pageData.stats} />
        <section className="discover">
          <div className="viewport">
            <div className="inner">
              {/*<h2 className="center-title">This week new episodes</h2>*/}
              <img className="welovemondays" src="/assets/images/welovemondays.gif" alt="we love monday" />
              {pageData.displayedVideos.length === 0 && (
                  <div className="empty">
                    <h2 className="title">{t('No_Result_For_Your_Search')}</h2>
                  </div>
              )}
              {pageData.displayedVideos.length > 0 && (
                  <VideoList
                      videos={pageData.displayedVideos}
                      limit={pageData.displayedVideos.length}
                      onVideoClicked={
                        (id) =>
                            pageClicked("home", "/video", id)
                      }
                  />
              )}
              {pageData.canDisplayMore && (
                  <div className="load-more" style={{ marginTop: 0 }}>
                    <button className="tertiary small" onClick={showMoreVideos}>
                      <span>{pageData.isFetchingVideos ? t('Loading') : t('Load_More')}</span>
                    </button>
                  </div>
              )}
            </div>
          </div>
        </section>
        {/* <section className="discover">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t('Explore')}</h2>
              <div className="categories">
                <Link
                  to="/category/fb"
                  onClick={() => pageClicked('/category', 'F&B')}
                  style={{ color: '#d2d2d2', textDecoration: 'none' }}>
                  <div className="category fnb">
                    <div className="img-wrapper">
                      <img src="/assets/images/cat-fnb.jpg" alt="" />
                      <div className="logo"></div>
                    </div>
                    <div className="cat-title">{t('FNB')}</div>
                  </div>
                </Link>
                <Link
                  to="/category/operations"
                  onClick={() => pageClicked('/category', 'OPS|HR')}
                  style={{ color: '#d2d2d2', textDecoration: 'none' }}
                >
                  <div className="category ope">
                    <div className="img-wrapper">
                      <img src="/assets/images/cat-ope.jpg" alt="" />
                      <div className="logo"></div>
                    </div>
                    <div className="cat-title">{t('OPSHR')}</div>
                  </div>
                </Link>
                <Link
                  to="/category/sm"
                  onClick={() => pageClicked('/category', 'S&M')}
                  style={{ color: '#d2d2d2', textDecoration: 'none' }}>
                  <div className="category snm">
                    <div className="img-wrapper">
                      <img src="/assets/images/cat-snm.jpg" alt="" />
                      <div className="logo"></div>
                    </div>
                    <div className="cat-title">{t('SNM')}</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section> */}

        <section className="series">
          <div className="viewport">
            <div className="inner">
              {/*<h2 className="title">Trending series</h2>*/}
              <div className='title-wrapper'>
                <h2 className="center-title">
                  {t('Dig_Use_&_Share')}
                  <h2 style={{ whiteSpace: 'nowrap', all: 'inherit' }}>{t('Anywhere_Anytime')}</h2>
                </h2>
              </div>
              <SeriesSlider.Home
                onSeriesClicked={
                  (id) =>
                    pageClicked("/series", id)
                }
                // series={pageData.trendingSeries}
              />
            </div>
          </div>
        </section>
        
        <HyattClassics />

        <HyattPhotography />


        <section className="focus">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t('Focus')}</h2>
              <div className="sustainability-container">
                {/* <video
                  id="sustainability-video"
                  type="video/mp4"
                  src="/assets/videos/IDEAS-anim-WorldOfCare.mp4"
                  muted
                  autoPlay
                  loop
                  playsInline
                  webkit-playsinline
                  height="100%"
                  x5-video-player-type="h5"
                  x5-video-player-fullscreen="false"
                  x5-video-orientation="landscape"
                  onClick={() => props.history.push(`/sustainability`)}
                >
                </video> */}

                {/* <div className="cover"></div> */}
                <div id="sustainability-video" onClick={() => props.history.push(`/sustainability`)}>
                  <canvas id="sustainability-canvas"></canvas>
                </div>

                {/* <div class="jsmpeg"
                  data-url="assets/videos/sustainability-worldofcare.ts" 
                  // data-loop="true" 
                  // data-autoplay="true" 
                  // data-disabl-gl="true"
                  // data-disable-webAssembly="true"
                  data-audio="false"
                >
                </div> */}

              {/* <iframe
                  src="//fast.wistia.net/embed/iframe/d1t2jjba91?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false"
                  frameBorder="0"
                  scrolling="no"
                  className="video-home"
                  name="hero_embed1"
                ></iframe> */}

              </div>
            </div>
          </div>
        </section>
        {/*<section className="discover">*/}
        {/*  <div className="viewport">*/}
        {/*    <div className="inner">*/}

        {/*      <div className="tags">*/}
        {/*        {pageData.tags.length > 0 && (*/}
        {/*          <Link to="/search?q=&tags=&filter=episodes">*/}
        {/*            <span*/}
        {/*              className="tag tag-ur-it"*/}
        {/*              // onClick={() => changeTag(null)}*/}
        {/*            >*/}
        {/*              All*/}
        {/*            </span>*/}
        {/*          </Link>*/}
        {/*        )}*/}
        {/*        {pageData.tags.map(*/}
        {/*          (t) =>*/}
        {/*            !t.disabled && (*/}
        {/*              <Link to={`/search?q=&tags=${t.id}&filter=episodes`}>*/}
        {/*                <span*/}
        {/*                  key={t.id}*/}
        {/*                  className="tag"*/}
        {/*                  // onClick={() => changeTag(t.id)}*/}
        {/*                >*/}
        {/*                  {t.name}*/}
        {/*                </span>*/}
        {/*              </Link>*/}
        {/*            )*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*      {pageData.displayedVideos.length === 0 && (*/}
        {/*        <div className="empty">*/}
        {/*          <h2 className="title">No result for your search</h2>*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*      {pageData.displayedVideos.length > pageData.discoverDisplay && (*/}
        {/*        <div className="load-more">*/}
        {/*          <Link to="/search?q=&tags=">*/}
        {/*            <button*/}
        {/*              className="tertiary small"*/}
        {/*              // onClick={showMoreVideos}*/}
        {/*            >*/}
        {/*              <span>Explore all</span>*/}
        {/*            </button>*/}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <TrendingSpeakers
          onSpeakerClicked={
            (id) =>
              pageClicked("/speaker", id)
          }
          speakers={pageData.trendingSpeakers}
        />
        <HdeBanner
          title={pageData.hdeData.title}
          video={pageData.hdeData.video}
          image={pageData.hdeData.image}
          desc={pageData.hdeData.desc}
        />
        {/* <section className="leaderboard">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">Leaderboard</h2>
              <LeaderBoard data={pageData.leaderboard} region={pageData.leaderBoardRegion} />
            </div>
          </div>
        </section> */}

      </div>
    </Wrapper>
  );
};

export default Home;
