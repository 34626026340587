import React from 'react';
import { useGlobalContext } from '../../../store/global';

export default function Thanks() {
  const { closeModal } = useGlobalContext();

  return (
    <div className="popup-inner thanks" style={{ maxWidth: '650px' }}>
      <div className="popup-title">
        <h2>Unexpected Error</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content thanks-content">
        <p>An unexpected error has occured.</p>
        <div className="popup-action">
          <button className="primary big" onClick={closeModal}>
            Back to I.D.E.A.S.
          </button>
        </div>
      </div>
    </div>
  );
}
