import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";
import { useTranslation } from 'react-i18next'

const NavProfileSelector = ({
  pageClicked,
  handleLogout,
  userInfo,
  isMobile,
  parentModalClick,
  handleOpenPopup,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (!ref.current.contains(event.target)) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document.getElementById("profile-content" + (isMobile ? "-mobile" : "")).classList.remove("tab-opened");
        document.getElementById("profile-tab" + (isMobile ? "-mobile" : "")).classList.remove("active");
      } else {
        document.getElementById("profile-content" + (isMobile ? "-mobile" : "")).classList.add("tab-opened");
        document.getElementById("profile-tab" + (isMobile ? "-mobile" : "")).classList.add("active");
        return true;
      }
      return false;
    });
  };

  return (
    <div
      id={"profile-tab" + (isMobile ? "-mobile" : "")}
      className={"tab-select-wrapper profile-select burger " + (isMobile ? "" : "hidden-md ")}
    >
      <div
        className="profile-info"
        onClick={handleClick}
      >
        <div className="avatar">
          <div className="medias">
            {get(userInfo, "profilePicture.uri", "") !==
              "" && (
                <span
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMG_URL
                    }/storage/user-picture/${get(
                      userInfo,
                      "profilePicture.uri",
                      ""
                    )}`,
                  }}
                ></span>
              )}
            {get(userInfo, "profilePicture.uri", "") ===
              "" && (
                <span
                  style={{
                    backgroundImage: `url('/assets/images/profile.png')`,
                  }}
                ></span>
              )}
          </div>
        </div>
        <div className="username">
          {`${userInfo?.name}, ${userInfo?.surname}`}<span className="icon-chevronright2"></span>
        </div>
      </div>

      <div
        id={"profile-content" + (isMobile ? "-mobile" : "")}
        className="tab-select"
        ref={ref}
      >
        <Link
          to="/profile"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/profile");
          }}
          className="tab"
        >
          {t('My_Profile')}
        </Link>
        <Link
          to="/my-list"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/my-list");
          }}
          className="tab"
        >
          {t('My_Lists')}
        </Link>
        <Link
          // to="/search?q=&tags=&filter=quiz"
          to="/profile?showQuizzes=true"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/search", "quiz");
            handleOpenPopup();
          }}
          className="tab"
        >
          {t('My_Quizzes')}
        </Link>
        <Link
          to="/leaderboard"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            pageClicked("/leaderboard");
          }}
          className="tab"
        >
          {t('Leaderboard')}
        </Link>
        <Link
          to="/"
          className="tab"
          onClick={() => {
            handleClick();
            if (isMobile) {
              parentModalClick();
            }
            handleLogout();
          }}
        >
          {t('Log_Out')}
          <span className="icon logout icon-logout"></span>
        </Link>
      </div>
    </div>
  );
};

export default NavProfileSelector;
