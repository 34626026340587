import React from 'react';
import { useGlobalContext } from '../../../store/global';

export default function Thanks() {
  const { closeModal } = useGlobalContext();

  return (
    <div className="popup-inner" style={{ width: 'unset' }}>
      <div className="popup-title">
        <h2>Authorization Failed</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content">
        <p>Your username or password are incorrect</p>
        {/* <p>Thank you.</p> */}
        <div className="popup-action">
          <button className="primary big" onClick={closeModal}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}
