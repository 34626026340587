const mandarin = {
    // Menu
    Home: '主页',
    All_Categories: '所有类别',
    FNB: '餐饮',
    FNB_Blank: '餐饮',
    OPSHR: '运营｜人力资源',
    SNM: '销售&市场',
    SNM_Blank: '销售&市场',
    Search: '搜索',
    Search_Place_Holder: '系列，测验，主讲人',
    Series: '系列',
    Quizzes: '测验',
    Speakers: '主讲人',
    Speaker: '主讲人',
    Leaderboard: '榜单',
    My_Profile: '我的资料',
    My_Lists: '我的列表',
    My_Quizzes: '我的测验',
    Log_Out: '退出账号',

    // Home page
    WELCOME_BACK: '欢迎回来！',
    RECOMMENDED_FOR_YOU : '为您推荐',
    Episodes: '视频',
    //Quizzes(duplicated)
    INSPIRATION: '启发',
    DIRECTION: '引导',
    EXCELLENCE: '卓越',
    APPLICATION: '运用',
    SOLUTION: '解决',
    Home_Page_Content: 'I.D.E.A.S 2023 是什么？ 通过每周一发布的 5 分钟易懂易学小视频，让你可以从 50 多位管理者的分享中汲取知识并获得灵感，随时随地自主学习。这款寓教于乐型学习工具，致力于帮助大家成为更好的管理者并更多地关爱彼此。学员通过互动测验可以学习如何更好地管理酒店客房、餐厅和酒吧，了解更多的销售技巧和数字通信的最佳实践，掌握更多宴会销售、企划和运营的解决方案。特此献给所有亚太地区凯悦集团酒店行政委员会及所有相关团队。',
    Countries: '国家',
    Markets: '市场',
    Hotels: '酒店',
    //Series(duplicated),
    //Episodes(duplicated),
    //Quizzes(duplicated),
    Users: '用户',
    We_Love_Mondays: '我们热爱周一',
    Viewed: '已观看',
    Views: '观看',
    Load_More: '加载更多',
    Explore: '探索更多',
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    Trending_Series: '热门系列',
    Dig_Use_Share_Anywhere_Anytime: 'Dig, use & share\nanywhere, anytime',
    With: '和 ',
    Videos: ' 视频',
    Trending_Speakers: '热门主讲人',
    Discover_Series: '发现系列',
    //return by backend,
    //return by backend,
    //return by backend,
    Have_Suggestions_For_Improvements: '您有帮助我们改进的建议？',
    Give_Us_Your_Ideas: '请把您的想法告诉我们',
    Terms_And_Conditions: '条款和条件',
    Focus: '关注',

    // EXPLORE ALL
    EXPLORE_THE_LIBRARY: '探索图书馆',
    Search_By_Theme_Topi_Or_Speaker: '按主题、话题或主讲人搜索',
    SERIES: '系列',
    EPISODES: '视频',
    QUIZZES: '测验',
    Watch_The_Series: '观看该系列',
    START: '现在开始',
    RETAKE: '再次尝试',
    Discover_More: '发现更多',
    All: '所有',
    Loading: '加载中...',

    // CATEGORY
    //Series(duplicated),
    Search_For_Series: '按系列搜索',
    Latest_Series: '最新系列',
    All_Series: '所有系列',
    View_Details: '查看详情',
    Watch: '观看',
    XEpisodes: ' 集',
    XEpisode: ' 集',
    Ready_For_A_Quiz: '准备好进行测验获取更多得分了吗？',
    Start: '现在开始',
    //Episodes(duplicated),
    //Search by theme, topic or speaker(duplicated),
    Sort_By: '排序方式',
    Default: '默认',
    Latest: '最新',
    Earliest: '最早',
    Most_Viewed: '最多观看',
    All_Episodes: '所有视频',
    //Quizzes(duplicated),
    Search_For_Quizzes: '按测验搜索',
    Recommended_For_You: '为您推荐',
    All_Quizzes: '所有测验',
    //Watch_The_Series(duplicated),
    Re_take: '再次尝试',
    //Watch_The_Series(duplicated),
    //Start: '',
    Passed: '通过',
    Failed: '未通过',
    //Discover more(duplicated),

    // EPISODE PAGE
    //[X] Episodes(duplicated),
    Related_Series: '相关系列',
    Share_Video: '分享视频',
    Watch_Later: '稍后观看',
    XView: ' 次观看',
    XViews: ' 次观看',
    //Speakers(duplicated),
    Released_On: '发布于 ',
    Discussion: '讨论',
    XComments: ' 条评论',
    XComment: ' 条评论',
    Reply: '回复',
    Enter_Your_Comment_Here: '在此添加您的评论',
    Cancel: '取消',
    Submit: '发布',
    Back_To_Top: '回到顶端',

    // LEADERBOARD
    Weekly_Leaderboard: '每周榜单',
    Expert_Viewer : '专业观众',
    Biggest_Viewed: '过去一周观看视频集数最多的观众',
    Fastest_Viewer: '早鸟观众',
    Woke_Up_Earilest: '本国观看视频的首位观众',
    Star_Contributor: '人气评论员',
    Posted_Greatest: '发表评论并获得最多点赞的观众',

    // PROFILE PAGE
    EDIT_PROFILE: '编辑资料',
    SHOW_HISTORY: '显示记录',
    MY_QUIZ_HISTORY: '我的测验记录',
    RE_TAKE: '再次尝试',
    BADGES: '奖章',
    //Expert_Viewer(duplicated)
    //Fatest_Viewer(duplicated)
    //Star_Contributor(duplicated)
    //QUIZZES(duplicated)
    PERFORMANCE: '表现',
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    Pts: '得分',
    PROGRESS: '进度',
    MY_ACTIVITY: '我的活动',
    MonthAndYear: '月，年',
    VIDEOS: '视频',
    QUIZZES_TAKEN: '参加测验',
    QUIZZES_PASSED: '通过测验',
    COMMENTS: '评论',
    // ???
    Videos_Watched: '观看视频',
    Quizzes_Taken: '参加测验',
    Comments: '评论',
    Week_1: '第2周',
    Week_2: '第2周',
    Week_3: '第3周',
    Week_4: '第4周',
    Week_5: '第5周',

    // NOTIFICATION
    Notification: '通知',
    Discover: '发现',
    Take_The_Quiz: '开始测验',

    // MY LISTS
    WATCH_LATER: '稍后观看',
    XVideos: ' 个视频',
    XVideo: ' 个视频',
    VIEWED: '已观看',
    Yesterday: '昨天',
    // ??? 
    Clear: '清除',
    Watc_Again: '再次观看',
    LIKED: '已赞',
    //Xvideos(duplicated)
    // OTHER USER PROFILE
    Back: '返回',
    Badges: '奖章',
    //Expert viewer(duplicated)
    //Fatest viewer(duplicated)
    //Star contributor(duplicated)
    //Quizzes(duplicated)
    //F&B(duplicated)
    //OPS | HR(duplicated)
    //S&M(duplicated)
    //Pts(duplicated)
    LIKED_VIDEOS: '赞过的视频',
    No_Result_For_Your_Search: '没有与您的搜索匹配的结果',

    // QUIZ INTRODUCTION
    Do_You_Remember: '',
    XQuestions: ' 问题',
    //START(duplicated)
    NEXT: '下一个',
    PREVIOUS: '上一个',

    // QUIZ RESULT
    You_Pass_GIF: '',
    Your_Score_Is: '您的得分是...',
    GIF_PASSED: '',
    Retake: '再次尝试',
    GIF_FAILED: '',
    XPoints: ' 得分',
    Xpoints: ' 得分',
    Dont_Worry: '不要担心。让我们看看哪里出了问题，理解问题，找到正确答案。',
    Help_Me_Pass: '助我通过',
    //Retake(duplicated)
    Almost_There: '快成功了！',
    Help_Description: `<1>您已经答对了 {{correctCount}} 个问题。</1> <2>以下是您错过的问题：</2>`,
    Find_The_Correct_Answer: `在<1> {{relatedEpisode}} </1>中找到正确答案`,
    Learn_From_Series: '从该系列中学习',
    Quizz_Welcome: "<1>您还记得关于 {{title}} 最重要的内容吗？</1> <2> 参加这个互动测验来测试您获取的知识, 并获得80/100或以上的得分。</2> <3> 如果您第一次没有通过，请不要灰心，您可以再次尝试，直到通过为止。</3>",
    Xquestions: ' 问题',
    //Retake(duplicated)

    // Lost

    // Menu
    Explore_All: '探索更多',
    No_Result_Found: '没有与您的搜索匹配的结果',
    Take_The_Quizz: '',

    // Home page
    Play_Episode: '播放视频',
    Start_Quiz: '开始测验',
    Visit_Now: '立即访问',
    Certificates: '证书',

    // Explore All
    Filter_By: '筛选',

    // Video / Episode
    In_Watch_List: '在观看列表中',
    Remove_From_Watchlist: '从观看列表中移除',
    Link_Copied: '视频链接已复制成功，去和你的同事们分享吧!',
    Xmins: '分钟',

    // Profile page
    Comments_Posted: '发布评论',
    January: '1月',
    February: '2月',
    March: '3月',
    April: '4月',
    May: '5月',
    June: '6月',
    July: '7月',
    August: '8月',
    September: '9月',
    October: '10月',
    November: '11月',
    December: '12月',
    Jan: '1月',
    Feb: '2月',
    Mar: '3月',
    Apr: '4月',
    Jun: '6月',
    Jul: '7月',
    Aug: '8月',
    Sep: '9月',
    Oct: '10月',
    Nov: '11月',
    Dec: '12月',

    // Profile Edit page

    // My List page
    Today: '今天',
    A_Week_Ago: '一周前',
    A_Month_Ago: '一个月前',

    // Quizz page
    There_Are_More: '这个问题还有更多正确答案。再看看吧！',

    // 404 page
    Not_Be_Found: '无法找到所请求的资源。',
    Check_URL: '请检查网址并重试。',

    // Sustainability page
    sustainability: '可持续发展',
    sustainability_solgan: '减少食物浪费，消除塑料废物，开展环保行动',
    sustainability_description: '一个健康的环境对于每个人的尽善尽美至关重要',

    //Update
    'Hotel_Statistics': '酒店数据',
    'Directory': '目录',
    "Welcome_to_I.D.E.A.S": '欢迎来到I.D.E.A.S 2024！通过每周一发布的5分钟易懂易学的大师分享视频，您可以从60多位管理者那里汲取知识、增强技能。按照自己的节奏，随时随地灵活学习。我们的平台发布互动式测验，并附有经过认证的证书，以提高您对酒店客房、餐厅、酒吧和活动的管理水平。探索有效的酒店销售技巧、数字战略和最佳实践。学习创新的销售、活动策划和运营方法，成为领导者，并培养自己的团队掌握更多宴会销售、企划和运营的解决方案。专为所有凯悦酒店集团亚太地区的领导委员会及其团队量身定制。',
    "HYATT": "餐饮摄影集",
    "PHOTOGRAPHY": "影集",
    "Dig_Use_&_Share": "挖掘，利用，分享",
    "Anywhere_Anytime": "任何地点，任何时间",
    "MoodBoard": "情绪版",
    "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "凯悦全球餐饮摄影集",
    "Leaderboard_Badges": "排行榜徽章",
    "Show_Full_History": "查看全部历史",
    "Average_of_users_have_watched...": "平均每个用户收看",
    "...videos": "集",
    "Average_of_users_have_taken...": " 平均每个用户完成",
    "...number_of_quizzes": "个测验",
    "Average_of_users_have_passed...": " 平均每个用户通过",
    "...quizzes": "个测验",
    "Image_upload": "上传图片",
    "Enter_your_comment_here": "在此输入您的评论",
    "Series_summary": "系列摘要",
    "Download": "下载",
    "Completions": "完成观看",
    passed: "passed",
    view: "（次）观看",
    views: "（次）观看",
    point: "得分",
    points: "得分",
}

export default mandarin
