import React, { useEffect } from 'react';
// import { Redirect } from 'react-router-dom'
import { useGlobalContext } from '../store/global';

export default function CustomRedirect() {
  const { setLoading } = useGlobalContext();

  useEffect(() => {
    setLoading(true);
    window.location.href = process.env.REACT_APP_SSO_URL;
  }, []);

  return <>{/* <Redirect to="/auth" /> */}</>;
}
