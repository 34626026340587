import React, { useState, useEffect, useRef } from "react";
import Comment from "./comment";
import { flattenTree, getCommentTree } from "../../../utils/index";
import { likeComment, getProfile, postImageComment } from "../../../utils/data";
import get from "lodash.get";
import { sortByCreated } from "../../../utils";
import { useGlobalContext } from "../../../store/global";
import { useTranslation } from "react-i18next";
import ImageCommentsButton from "../../ui/imageCommentsButton";

export default function VideoDiscussion(props) {
  const { comments, videoId, topLevelComment, refreshVideo } = props;
  const { openModal } = useGlobalContext();
  const { t } = useTranslation();

  const [imgComment, setImgComment] = useState(null);
  const [componentData, setComponentData] = useState({
    commentTree: [],
    profile: {},
    limit: 20,
  });

  useEffect(() => {
    getProfile().then((res) => {
      // const tree = simpleUnflatten(comments);
      const tree = flattenTree(getCommentTree(null, comments));

      setComponentData((prevState) => ({
        ...prevState,
        profile: res.data,
        commentTree: tree || [],
      }));
    });
  }, [comments]);

  const sendComment = () => {
    const textfield = document.getElementById("toplevel");
    if (textfield.value) {
      openModal("comment");
      topLevelComment(textfield.value, null, imgComment);
      setImgComment(null);
      textfield.value = "";
    }
  };

  const like = (id, bool) => {
    likeComment(id, bool).then((res) => {
      refreshVideo(videoId, null, true);
    });
  };

  const increaseLimit = (e) => {
    e.preventDefault();
    setComponentData((prevState) => ({
      ...prevState,
      limit: prevState.limit + 10,
    }));
  };

  return (
    <div className="comments">
      <h2 className="title">{`${t("Discussion")}(${
        componentData.commentTree.length
      } ${
        componentData.commentTree.length === 1 ? t("XComment") : t("XComments")
      })`}</h2>
      {componentData.commentTree.sort(sortByCreated) &&
        componentData.commentTree.map((c, i) =>
          i < componentData.limit && c.user ? (
            <Comment
              key={c.id}
              comment={c}
              refreshVideo={refreshVideo}
              like={like}
              openModal={openModal}
              profile={componentData.profile}
            />
          ) : null
        )}
      <div className="comment-item-write">
        {get(componentData, "profile.profilePicture.uri", "") !== "" && (
          <div className="profile-pic">
            <span
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMG_URL
                }/storage/user-picture/${get(
                  componentData,
                  "profile.profilePicture.uri",
                  ""
                )})`,
              }}
            ></span>
          </div>
        )}
        {get(componentData, "profile.profilePicture.uri", "") === "" && (
          <div className="profile-pic">
            <span
              style={{ backgroundImage: `url('/assets/images/profile.png')` }}
            ></span>
          </div>
        )}
        <div className="comment-content">
          <div className="comment-author">{`${get(
            componentData.profile,
            "name",
            ""
          )} ${get(componentData.profile, "surname", "")}`}</div>

          <div className="form-item" style={{ position: "relative" }}>
            <textarea name="" id="toplevel" cols="30" rows="4"></textarea>
            <ImageCommentsButton
              imgComment={imgComment}
              setImgComment={setImgComment}
            />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'end',
          }}>
            <span style={{
              fontSize: 12,
              fontWeight: 500,
              opacity: 0.7,
            }}>Image comment should be less than 30MB</span>
          </div>
          <div className="form-actions">
            <button className="x-small primary" onClick={sendComment}>
              <span>{t("Submit")}</span>
            </button>
          </div>
        </div>
      </div>
      {componentData.commentTree.length > componentData.limit && (
        <div className="load-more">
          <button className="small tertiary" onClick={increaseLimit}>
            <span>{t("Load_More")}...</span>
          </button>
        </div>
      )}
    </div>
  );
}
