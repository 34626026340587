import React from 'react';
import { withRouter } from 'react-router';
import Wrapper from '../../pages/wrapper';
import { useTranslation } from "react-i18next";

const NotFound = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div id="content">
        <div className="viewport not-found">
          {/* <img className="not-found__guy" src="/assets/images/author-2.jpg" alt="404" /> */}
          <h1>{t('Not_Be_Found')}</h1>
          <h2>{t('Check_URL')}</h2>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(NotFound);
