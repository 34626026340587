import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { addToWatchlist } from "../../utils/data";
import { parseCategoryID } from "../../utils/index";
import get from "lodash.get";
import { useTranslation } from 'react-i18next'


function VideoItem(props) {
  const { video, onVideoClicked, onWatchlistDelete, hideWatchlist, isInSearch } = props;
  const [isWatchlist, setIsWatchlist] = useState(video.userWatchlist || false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isInSearch) {
      video.totalViews = video.morningViewCount || 0;
    } else {
      video.totalViews = (video.anonymousViewCount || 0) + (video.views || 0);
    }
  })

  const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClick = (e) => {
    if (e.target.className.includes("speaker")) {
      const url = `/speaker/${e.target.className.split(" ")[1]}`;

      if (e.button === 1) {
        window.open(url, "_blank");
      } else if (e.button === 0) {
        props.history.push(url);
      }
    } else {
      if (e.button === 1) {
        if (onVideoClicked) {
          onVideoClicked(video.id);
        }
        const win = window.open(`/video?id=${video.id}`, "_blank");
        win.focus();
      } else if (e.target.className.includes("watch-later")) {
        // addToWatchlist(video.id, true);
        if (!isWatchlist) {
          setIsWatchlist(true);

          addToWatchlist(video.id, true)
            .then()
            .catch(() => setIsWatchlist(false));
        } else if (onWatchlistDelete && isWatchlist) {
          addToWatchlist(video.id, false);
          setIsWatchlist(false);
          onWatchlistDelete(video.id);
        }
      } else if (e.button === 0) {
        if (onVideoClicked) {
          onVideoClicked(video.id);
        }
        props.history.push(`/video?id=${video.id}`);
      }
    }
  };

  return (
    <div className="video-item">
      <div
        // to={`/video?id=${video.id}`}
        style={{ color: "white", textDecoration: "none", pointerEvents: "all" }}
        onMouseDown={handleClick}
      >
        <div className="inner">
          <div className="player-wrapper">
            <div className="cover">
              {!hideWatchlist && (
                <span className={`watch-later`}>
                  <i
                    className={
                      isWatchlist
                        ? onWatchlistDelete
                          ? "icon-trash"
                          : "icon icon-check"
                        : "icon icon-clock-outline-1"
                    }
                  ></i>
                  {isWatchlist
                    ? onWatchlistDelete
                      ? t('Remove_From_Watchlist')
                      : t('In_Watch_List')
                    : t('Watch_Later')}
                </span>
              )}
            </div>
            {get(video, "videoThumbnail.uri", "") !== "" && (
              <div
                className="video-wrapper"
                style={{
                  backgroundImage:
                    get(video, "videoThumbnail.uri", "") !== ""
                      ? `url(${
                          process.env.REACT_APP_IMG_URL
                        }/storage/video-thumbnail/${get(
                          video,
                          "videoThumbnail.uri",
                          ""
                        )})`
                      : 'url("/assets/images/video-1.jpg")',
                }}
              >
                <div className={`hover-content ${
                  parseCategoryID(get(video, "category.id", "")).categoryShorthand
                }`}>
                  <div className="description">{video.description}</div>
                </div>
              </div>
            )}
            {get(video, "videoThumbnail.uri", "") === "" && (
              <div className="video-wrapper">
                {/* {!hideWatchlist && (
                  <span
                    className={`watch-later ${showAction ? "visible" : ""}`}
                  >
                    <i
                      className={
                        isWatchlist
                          ? onWatchlistDelete
                            ? "icon-trash"
                            : "icon icon-check"
                          : "icon icon-clock-outline-1"
                      }
                    ></i>
                    {isWatchlist
                      ? onWatchlistDelete
                        ? "Remove from watchlist"
                        : "In watchlist"
                      : "Watch later"}
                  </span>
                )} */}
                <iframe
                  src={`//fast.wistia.net/embed/iframe/${video.wistiaHashId}?fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&autoplay=false&smallPlayButton=false&fullscreenButton=false`}
                  frameBorder="0"
                  scrolling="no"
                  // className="video-wrapper-iframe"
                  name="hero_embed"
                ></iframe>
                <div className={`hover-content ${
                  parseCategoryID(get(video, "category.id", "")).categoryShorthand
                }`}>
                  <div className="description">{video.description}</div>
                </div>
              </div>
            )}
          </div>
          <div className="infos">
            <div className="right-info">
              <span
                className={`cat ${
                  parseCategoryID(get(video, "category.id", "")).categoryShorthand
                }`}
              >
                {parseCategoryID(get(video, "category.id", "")).categoryName}
              </span>
              {video.isSustainability && (
                <div className="sustainability-tag-wrapper">
                  <span className="icon-sustainability"></span>
                  <span className="text-sustainability">sustainability</span>
                </div>
              )}
              <span className="time">{parseTime(video.duration)}</span>
            </div>
            
            <div className="left-info">
              {video.userComplete && (
                <span
                  className={`viewed ${
                    parseCategoryID(get(video, "category.id", ""))
                      .categoryShorthand
                  }`}
                >
                  <span className="icon-check"></span>{t('Viewed')}
                </span>
              )}
              <span className="views">
                {/*{(video.anonymousViewCount || 0) + (video.views || 0)} {t('Views')}*/}

                {/*{isInSearch ? (video.morningViewCount || 0) + ' ' + (t('Views')) : ((video.anonymousViewCount || 0) + (video.views || 0)) + ' ' + (t('Views')) }*/}
                {(video.totalViews || 0)} {video.totalViews > 1 ? t('XViews') : t('XView')}
              </span>
              <span className={`like-count${video.liked ? " active" : ""}`}>
                <span className="icon-Heart"></span>
                {video.likes}
              </span>
            </div>
          </div>
          <div className="video-title">{video.title}</div>
          {(video?.speakers?.length > 0 && video?.speakers.filter(s => s !== null).length > 0) && (
            <div className="author">
              <span>{t('With')}: </span>
              {video.speakers.filter(speaker => speaker !== null).map((speaker, index) => [
                <span key={speaker.id || speaker._id} className={`speaker ${speaker.id || speaker._id}`}>{speaker.name} {speaker.surname}</span>,
                index === video.speakers.length - 1 ? null : ", ",
              ])}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

VideoItem.Quiz = ({ quiz }) => {
  return (
    <div className="video-item">
      <Link
        to={`/quiz?id=${quiz?.id}`}
        style={{ color: "white", textDecoration: "none", pointerEvents: "all" }}
      >
        <div className="inner">
          <div className="player-wrapper">
            <div className="cover"></div>
            {get(quiz, "thumbnail.uri", "") !== "" && (
              <div
                className="video-wrapper"
                style={{
                  backgroundImage:
                    get(quiz, "thumbnail.uri", "") !== ""
                      ? `url(${
                          process.env.REACT_APP_IMG_URL
                        }/storage/quiz-thumbnail/${get(
                          quiz,
                          "thumbnail.uri",
                          ""
                        )})`
                      : 'url("/assets/images/video-1.jpg")',
                }}
              ></div>
            )}
          </div>
          <div className="infos">
            <span
              className={`cat ${
                parseCategoryID(get(quiz, "category.id", "")).categoryShorthand
              }`}
            >
              {parseCategoryID(get(quiz, "category.id", "")).categoryName}
            </span>
            {quiz?.passed && (
              <span
                className={`done ${
                  parseCategoryID(get(quiz, "category.id", ""))
                    .categoryShorthand
                }`}
              >
                Done
              </span>
            )}
          </div>
          <div className="video-title">{quiz?.title}</div>
        </div>
      </Link>
    </div>
  );
};

export default withRouter(VideoItem);
