import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './constants/lang/english'
import mandarin from './constants/lang/mandarin'
import japanese from './constants/lang/japanese'

i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            english: {
                translation: {
                    ...english,
                },
            },
            mandarin: {
                translation: {
                    ...mandarin,
                },
            },
            japanese: {
                translation: {
                    ...japanese,
                },
            }
        },
        // if you're using a language detector, do not define the lng option
        lng: localStorage.getItem('lang') || 'english',
        fallbackLng: ['english', 'mandarin', 'japanese'],
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    })