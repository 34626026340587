class Tracking {
	pageView(pageURL) {
		try {
			if(window._hmt) {
				window._hmt.push(['_trackPageview', pageURL]);
			}
		} catch(e) { console.log('TRACKING ERROR', e)}
	}

	event(type, action, label, value) {
		try {
			if(window._hmt)
				window._hmt.push(['_trackEvent', type, action, label, value]);
		} catch(e) {}
	}
}

export default new Tracking()