import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { getMyNotifications, readMyNotifications } from "../../utils/data";
import tracking from "../../utils/tracking";
import { useTranslation } from 'react-i18next'

import moment from "moment";

const Notifications = ({
  inWrapper,
  isMobile,
  parentModalClick
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);
  const [notifsList, setNotifsList] = useState([]);

  const haveNewNotif = useMemo(() => {
    for (const notif of notifsList) {
      if (notif.status !== "read") {
        return true;
      }
    }

    return false;
  }, [notifsList]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchNotif();
    const interval = setInterval(() => {
      fetchNotif();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchNotif = async () => {
    if (showModal) return;

    const notifs = await getMyNotifications();
    setNotifsList(notifs?.data || []);
  };

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (
          (!ref.current.contains(event.target) &&
            !event.target.className.includes("icon-bell")) ||
          event.target.className.includes("layer-notif")
        ) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document
          .getElementById("body")
          .classList.remove("notifications-opened");
      } else {
        tracking.event("header", "notification", "open");
        document.getElementById("body").classList.add("notifications-opened");
        return true;
      }
      return false;
    });
  };

  const cleanActionTitle = {
    comment_liked: "new like",
    comment_replied: "new reply",
    award_obtained: "congratulations",
    new_quiz: "New quiz online!",
    new_leaderboard: "leaderboard",
  };

  const ActionLink = ({ notif }) => {
    const linkClicked = () => {
      readMyNotifications(notif.id);
      if (isMobile) {
        parentModalClick();
      }
      handleClick();

      setNotifsList((prevList) => {
        let newList = [...prevList];
        const notifIndex = newList.findIndex((n) => n.id === notif.id);
        newList[notifIndex].status = "read";

        return newList;
      });
    };

    switch (notif.type) {
      case "comment_liked":
        return (
          <Link onClick={linkClicked} to={`/video?id=${notif.video}`}>
            {t('Discover')}
          </Link>
        );
      case "comment_replied":
        return (
          <Link onClick={linkClicked} to={`/video?id=${notif.video}`}>
            {t('Discover')}
          </Link>
        );
      case "award_obtained":
        return (
          <Link onClick={linkClicked} to={`/leaderboard`}>
            {t('Discover')}
          </Link>
        );
      case "new_quiz":
        return (
          <Link onClick={linkClicked} to={`/quiz?id=${notif.quiz}`}>
            {t('Take_The_Quiz')}
          </Link>
        );
      case "new_leaderboard":
        return (
          <Link onClick={linkClicked} to={`/leaderboard`}>
            {t('Discover')}
          </Link>
        );
      default:
        return "";
    }
  };

  return (
    <div className={"notifications" + (inWrapper ? "" : " hidden-md")}>
      {inWrapper && (
        <div className="burger" onClick={handleClick}>
          <span className={"icon " + (haveNewNotif ? "icon-bell red" : "icon-bell")}></span>
          <span>{t('Notification')}</span>
        </div>
      )}
      {!inWrapper && (
        <a
          className={"icon " + (haveNewNotif ? "icon-bell red" : "icon-bell")}
          onClick={handleClick}
        />
      )}

      {/* <div style={{width: '100vw', heigt: '100vh' }}> */}
      <div ref={ref} className="nav-wrapper notifications">
        <div className="layer-notif" />
        <div className="nav-inner">
          <div className="close icon-x" onClick={handleClick}></div>
          <h2>{t('Notification')}</h2>
          <div className="item-wrapper">
            {!notifsList || notifsList.length === 0 ? (
              <div className="item read">
                <h3>You have no notifications yet</h3>
              </div>
            ) : (
              notifsList
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((notif, index) => (
                  <div
                    key={index}
                    className={`item ${
                      notif.status !== "read" ? "unread" : "read"
                    }`}
                  >
                    <div>
                      <h3>{cleanActionTitle[notif.type]}</h3>
                      <p>{notif.text}</p>
                      <div>{moment(notif.createdAt).fromNow()}</div>
                    </div>
                    <ActionLink notif={notif} />
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Notifications;
