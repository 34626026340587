import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { setNewPassword } from '../../../utils/data';
import { useGlobalContext } from '../../../store/global';

const Reset = (props) => {
  const { openModal } = useGlobalContext();

  const getToken = () => {
    const { search } = props.location;
    const { token } = queryString.parse(search);

    if (token) {
      return token;
    } else {
      return '';
    }
  };

  const handleReset = async () => {
    const pw = document.getElementById('password');
    const conf = document.getElementById('confirm');

    if (pw.value && conf.value && pw.value === conf.value) {
      const newpw = await setNewPassword(pw.value, getToken());
      if (newpw.status === 200) {
        localStorage.clear()
        openModal('pwreset');
      } else {
        openModal('fail');
      }
    }
  };
  // user lands on page with password reset code, can submit form, only resets if code is valid
  return (
    <div id="page">
      <div className="signin">
        {/* <div classNamee="logo-wrapper">
					<div className="hyatt icon-HYATT"></div>
					<div className="ideas icon-IDEAS"></div>
				</div> */}

        <div className="form-wrapper">
          <div className="form-inner">
            <div className="form-title">
              <h2>Reset your password?</h2>
              <p>
                Please enter your email address to
                <br />
                request a password reset
              </p>
            </div>
            <div className="form-item">
              <label htmlFor="">New Password</label>
              <input id="password" type="password" />
            </div>
            <div className="form-item">
              <label htmlFor="">Confirm Password</label>
              <input id="confirm" type="password" />
            </div>
            <div className="form-actions">
              <button className="big primary" onClick={handleReset}>
                <span>Confirm new password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Reset);
