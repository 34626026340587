import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './router';
import { GlobalProvider } from './store/global';
import './i18n.js'

ReactDOM.render(
  <GlobalProvider>
    <AppRouter />
  </GlobalProvider>,
  document.getElementById('root')
);

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?3f699566f8f159c99b5cf0d1bb76d522";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();