import React from "react";
import { useTranslation } from 'react-i18next'
import classicsImage from './HYATT-Classic.png'

const SearchBar = ({
  searchValue,
  onSearchChange,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <img className="classics-logo" src={classicsImage} alt="hyatt classics" />

      <h1 className="classics-title">Recipe</h1>
                
      <div className="form-item form-search">
        <div
          className="search-button"
          onClick={onClick}
        />
        <input
          id="searchbar"
          type="text"
          autoComplete="off"
          placeholder="Search for recipes"
          value={searchValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") onClick();
          }}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
    </>
  );
};

export default SearchBar;