import React, { useState, useEffect, useRef } from "react";

const NavLanguageSwitcher = ({
  currentLang,
  handleSwitchLang,
  isMobile,
  parentModalClick,
}) => {
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (!ref.current.contains(event.target)) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document.getElementById("languages-content" + (isMobile ? "-mobile" : "")).classList.remove("tab-opened");
        document.getElementById("languages-tab" + (isMobile ? "-mobile" : "")).classList.remove("active");
      } else {
        document.getElementById("languages-content" + (isMobile ? "-mobile" : "")).classList.add("tab-opened");
        document.getElementById("languages-tab" + (isMobile ? "-mobile" : "")).classList.add("active");
        return true;
      }
      return false;
    });
  };

  return (
    <div
      id={"languages-tab" + (isMobile ? "-mobile" : "")}
      className={"tab-select-wrapper languages-select burger " + (isMobile ? "" : "hidden-md ")}
    >
      <div className="tab-name language-tab" onClick={handleClick}>
        {currentLang}
        <span className="icon-chevronright2"></span>
      </div>

      <div
        id={"languages-content" + (isMobile ? "-mobile" : "")}
        className="tab-select"
        ref={ref}
      >
        {currentLang != 'English' && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang('english');
            }}
          >
            <span>English</span>
          </div>
        )}
        {currentLang != '日本語' && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang('japanese');
            }}
          >
            <span>日本語</span>
          </div>
        )}
        {currentLang != '中文' && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang('mandarin');
            }}
          >
            <span>中文</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavLanguageSwitcher;
