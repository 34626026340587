import React, { useEffect, useState } from 'react';
import Ingredients from '../pages/classics/Ingredients.png'

const RecipeModal = ({ wistiaHashId, description, apId }) => {
    const [visible, setVisible] = useState(true);

    return (
        <div className={'wrapper' + (visible ? '' : ' full')}>
            <div className='arrow' />
            <div className='button' onClick={() => setVisible(e => !e)} >
               <img src="/assets/images/dock_to_left.png" />
            </div>
            <RecipeVideoEmbed
                id={wistiaHashId}
                apiId={apId}
                // category={process.env.REACT_APP_FB}
                // series={pageData.seriesVideos}
                // quiz={pageData.seriesQuiz}
                // updateVideo={updateVideo}
                // selectedIndex={pageData.selectedIndex}
            />
                  {/* <div className='video-aspect'></div> */}
            <div className='ingredients-wrapper'>
              <div className='header'>
                <img src={Ingredients} alt="ingredients" />
              </div>

              <div className='separator' />

              <div className='content'>
                <p>{description}</p>
              </div>

              <div className='layer' />
            </div>
        </div>
    )   
}

export default RecipeModal;

const RecipeVideoEmbed = (props) => {
  const { id, apiId } = props;

  useEffect(() => {
    if (id) {
    //   let currentOrder = 0;
    //   const videoFromSeries = series.filter((sv) => sv.video.id === apiId)[0];
    //   if (videoFromSeries) {
    //     currentOrder = videoFromSeries.order;
    //   }
      if (!window._wq) return
      window._wq.push({
        id,
        options: {
          endVideoBehavior: 'default',
          playerColor: 'transparent',
        //   playerColor: parseCategoryID(category.id).categoryColor,
        },
        onHasData: function (video) {
          video.play();
          video.volume(1);
          video.bind('crosstime', 5, function () {
            // sendVideoView(apiId);
          });
          video.bind('crosstime', video.data.media.duration / 2, function () {
            // sendVideoHalfViewed(apiId);
          });
        //   video.bind('end', function () {
        //     let nextOrder = null;
        //     let nextPosition = null;
        //     let selectedIndex = -1
        //     for (const i in series) {
        //       if (currentOrder === series[i].order) {
        //         selectedIndex = i
        //       }
        //       if (series[i].order > currentOrder && (nextOrder === null || series[i].order < nextOrder)) {
        //         nextOrder = series[i].order;
        //         nextPosition = i;
        //       }
        //     }
        //     // console.log(`current Position: ${selectedIndex} (${currentOrder})`);
        //     // console.log(`next Position: ${nextPosition} (${nextOrder})`);

        //     if (nextPosition) {
        //       props.history.push(`/video?id=${series[nextPosition].video.id}`);
        //       // updateVideo(series[selectedIndex + 1].video.id)
        //     } else if (quiz) {
        //       props.history.push(`/quiz?id=${quiz.id}`);
        //     } else {
        //       props.history.push(`/video?id=${series[0].video.id}`);
        //       // updateVideo(series[0].video.id)
        //     }
        //   });
        },
      });
    }
    return () => {
      // prevents event handles from firing twice, and wistia from getting confused about
      // what video is playing, config etc. Just start fresh each time
      if (window.Wistia) {
        // window.Wistia.reinitialize();

        window.Wistia.trigger('ev1destroystart')
        window.Wistia._destructors.destroyAsyncEmbeds();
        window.Wistia._destructors.destroyEmbedShepherd();
        window.Wistia._destructors.destroyEmbedLinks();
        window.Wistia._destructors.destroyFullscreenTriggers();
        window.Wistia._destructors.destroyEventLoop();
        // window.Wistia._destructors.destroyVisitorKey(); // Crash
        window.Wistia._destructors.destroyWLog();
        window.Wistia._destructors.destroyMux();
        window.Wistia.trigger('ev1destroyend')

        window.Wistia.initializeOnce();
        window.Wistia.initializeEveryTime();
      }
      // -------------------------//
    }
  }, [apiId]);

  return (
    <div className="video-wrapper">
      {id && <div key={apiId} className={`wistia_embed wistia_async_${id} video-home`} />}
    </div>
  );
};
