import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import useNavSearch from "../../store/hooks/useNavSearch";
import NavSearchItem from "../ui/navSearchItem";
import { useTranslation } from 'react-i18next'

const NavSearchBar = ({
  pageClicked,
  isMobile,
  parentModalClick,
  handleClick,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchQ, setSearchQ] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [searchCategory, setSearchCategory] = useState(null);
  const [category, setCategory] = useState({});


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSearchChange = (e) => {
    setSearchValue(e)
    onQChange(e)
  }

  const onQChange = (e) => handleFilter(e, searchFilter)

  const onFilterChange = (e, category = {}) => {
    setCategory(category);
    handleFilter(searchValue, e, category.categoryId)
  }

  const {
    speakers,
    series,
    quizzes,
  } = useNavSearch(
    searchQ,
    searchFilter,
    searchCategory,
    true
  );

  const categories = [
    {
      categoryId: `${process.env.REACT_APP_FB}`,
      categoryShorthand: 'fnb',
      categorySlug: 'fb',
      categoryName: 'F&B',
      categoryColor: '#ff6600',
    },
    {
      categoryId: `${process.env.REACT_APP_OPERATIONS}`,
      categoryShorthand: 'ope',
      categorySlug: 'operations',
      categoryName: 'OPS | HR',
      categoryColor: '#76BD87',
    },
    {
      categoryId: `${process.env.REACT_APP_SM}`,
      categoryShorthand: 'snm',
      categorySlug: 'sm',
      categoryName: 'S&M',
      categoryColor: '#FF7DD7',
    },
  ];

  const handleFilter = (q, filter, category = null) => {
    setSearchQ(q);
    setSearchFilter(filter);
    setSearchCategory(category);
  }

  const handleCategory = (id) => {
    const catId = categories.filter(
      (c) => c.categoryId === id
    );

    setCategory(catId[0]);
    handleFilter(searchQ, searchFilter, id);
  }

  const handleClickOutside = (event) => {
    // setShowModal((prev) => {
    //   if (prev) {
    //     if (!ref.current.contains(event.target)) {
    //       handleClick(event);
    //     }
    //   }
    //
    //   return prev;
    // });

    if (!ref.current.contains(event.target)) {
      setShowModal(false);

      setType("");
      setSearchValue("");
      setSearchQ("");
      setSearchFilter("");
      setSearchCategory(null);
      setCategory({});
    }
  };

  // const handleClick = (e) => {
  //     document
  //       .getElementById("mobile-menu-tab")
  //       .classList.remove("menu-opened");
    // setShowModal((prev) => {
    //   if (prev) {
    //     // document.getElementById("search-content" + (isMobile ? "-mobile" : "")).classList.remove("tab-opened");
    //     // document.getElementById("search-tab" + (isMobile ? "-mobile" : "")).classList.remove("active");
    //     setType("");
    //     setSearchValue("");
    //     setSearchQ("");
    //     setSearchFilter("");
    //     setSearchCategory(null);
    //     setCategory({});
    //   } else {
    //     // document.getElementById("search-content" + (isMobile ? "-mobile" : "")).classList.add("tab-opened");
    //     // document.getElementById("search-tab" + (isMobile ? "-mobile" : "")).classList.add("active");
    //     return true;
    //   }
    //   return false;
    // });
  // };

  return (
    <div
      // id={"search-tab" + (isMobile ? "-mobile" : "")}
      // className={"search-wrapper burger " + (isMobile ? "" : "hidden-md ")}
      className={"search-wrapper burger " + (isMobile ? "" : "hidden-md ") + (showModal ? " active" : "")}
    >
      {isMobile &&
        <Link to="/search" className="tab-name visible-md mobile-search" style={{ margin: 0, marginTop: -3, marginRight: -11 }} onClick={handleClick}>
            <span className="icon-search"></span>
        </Link>
      }
      {/* ) : (
        <div className="tab-name hidden-md" onClick={handleClick}>
          {t('Search')}
          <span className="icon-search"></span>
        </div>
      )} */}

      <div
        // id={"search-content" + (isMobile ? "-mobile" : "")}
        // className="search-container "
        className={"search-container " + (showModal ? " tab-opened" : "")}
        ref={ref}
      >
        {type == "" && (
          <div className="inner">
            <div className="form-item form-search">
              <div
                className="search-button"
                onClick={() => onQChange(searchValue)}
              />
              <input
                id="searchbar"
                type="text"
                autoComplete="off"
                placeholder={t('Search_Place_Holder')}
                value={searchValue}
                onKeyPress={(e) => {
                  if (e.key === "Enter") onQChange(e.target.value);
                }}
                onChange={(e) => onSearchChange(e.target.value)}
              />
              {searchValue == "" && (
                <span className="icon-search"></span>
              )}
              {searchValue != "" && (
                <span className="icon-x" onClick={() => {
                  setType("");
                  setSearchValue("");
                  setSearchQ("");
                  setSearchFilter("");
                  setSearchCategory(null);
                  setCategory({});
                }}></span>
              )}
            </div>

            {searchValue == "" && (
              <div className="types-container">
                <div
                  className="type"
                  onClick={() => {
                    setSearchCategory(categories[0].categoryId);
                    onFilterChange("series", categories[0]);
                    setType("series");
                  }}
                >{t('Series')}
                </div>
                <div
                  className="type"
                  onClick={() => {
                    setSearchCategory(categories[0].categoryId);
                    onFilterChange("quizzes", categories[0]);
                    setType("quiz");
                  }}
                >{t('Quizzes')}
                </div>
                <div
                  className="type"
                  onClick={() => {
                    onFilterChange("speakers");
                    setType("speakers");
                  }}
                >{t('Speakers')}
                </div>
              </div>
            )}

            {searchValue != "" && (
              <div className="results-container">
                {series.list?.map(i => (
                  <NavSearchItem
                    item={i}
                    type="series"
                    path="/series/"
                    handleClick={handleClick}
                  />
                ))}
                {quizzes.list?.map(i => (
                  <NavSearchItem
                    item={i}
                    type="quiz"
                    path="/quiz?id="
                    handleClick={handleClick}
                  />
                ))}
                {speakers.list?.map(i => (
                  <NavSearchItem
                    item={i}
                    type="speakers"
                    path="/speaker/"
                    handleClick={handleClick}
                  />
                ))}

                {series.list.length == 0 && quizzes.list.length == 0 && speakers.list.length == 0 && (
                  <div className="result-item">{series.isLoading || quizzes.isLoading || speakers.isLoading ? t('Loading') : t('No_Result_Found')}</div>
                )}
              </div>
            )}

            {searchValue == "" && (
              <Link
                to="/search"
                onClick={() => {
                  handleClick();
                  // if (isMobile) {
                  //   parentModalClick();
                  // }
                  pageClicked("/search");
                }}
                className="explore-button"
              >
                {t('Explore_All')}
              </Link>
            )}
          </div>
        )}

        {type != "" && (
          <div className="inner">
            <div className="title-container">
              <div className="title">{type == "series" ? t('Series') : (type == "quiz" ? t('Quizzes') : t('Speakers'))}</div>
              <span className="icon-x" onClick={() => {
                setType("");
                setSearchValue("");
                setSearchQ("");
                setSearchFilter("");
                setSearchCategory(null);
                setCategory({});
              }}></span>
            </div>

            {(type == "series" || type == "quiz") && (
              <div className="categories-select">
                {categories.length > 0 && categories.map(c => (
                  <div
                    className={"category " + (searchCategory === c.categoryId ? c.categoryShorthand : "")}
                    onClick={() => handleCategory(c.categoryId)}
                  >{c.categoryName}</div>
                ))}
              </div>
            )}

            <div className="results-container">
              {type == "series" && series.list?.map(i => (
                <NavSearchItem
                  item={i}
                  type="series"
                  path="/series/"
                  handleClick={handleClick}
                />
              ))}
              {type == "quiz" && quizzes.list?.map(i => (
                <NavSearchItem
                  item={i}
                  type="quiz"
                  path="/quiz?id="
                  handleClick={handleClick}
                />
              ))}
              {type == "speakers" && speakers.list?.map(i => (
                <NavSearchItem
                  item={i}
                  type="speakers"
                  path="/speaker/"
                  handleClick={handleClick}
                />
              ))}

              {series.list.length == 0 && quizzes.list.length == 0 && speakers.list.length == 0 && (
                <div className="result-item">{series.isLoading || quizzes.isLoading || speakers.isLoading ? t('Loading') : t('No_Result_Found')}</div>
              )}
            </div>

            {(type == "series" || type == "quiz") && (
              <Link
                to={`/category/${category.categorySlug}?q=&tags=&filter=${type}`}
                onClick={() => {
                  handleClick();
                  // if (isMobile) {
                  //   parentModalClick();
                  // }
                  pageClicked("/category", category.categoryName);
                }}
                className="discover-button"
              >
                {t('Discover')}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavSearchBar;
